import React, { FC, useMemo } from "react";
import { FixedContainer, MenuIconStyled, TipsIconStyled } from "./styles";
import GenericButton from "../../atoms/GenericButton";
import {
  clearSearchResults,
  useMenuInteractive,
} from "../../../store/slices/menu.slice";
import { trackAnalyticsEvent } from "../../../utils/trackAnalyticsEvent";
import { toggleCategories, toggleWaiter } from "../../../store/slices/ui.slice";
import { useDispatch } from "react-redux";
import { useUi } from "../../../utils/hooks/useUi";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

const FloatingButton: FC<{ shift: boolean }> = ({ shift }) => {
  const dispatch = useDispatch();

  const { isCategorySelectorOpen, isWaiterOpen } = useUi();
  const { tips } = useMenuInteractive();
  const { t } = useTranslation();
  const theme: any = useTheme();

  const MenuButton = useMemo(
    () => (
      <GenericButton
        onClick={() => {
          dispatch(clearSearchResults());
          !isCategorySelectorOpen && trackAnalyticsEvent("popup-tocnew");
          dispatch(toggleCategories());
        }}
        style={{ color: theme.palette.primary.highlightIconFill }}
      >
        <MenuIconStyled />
        {t("BottomNav:menu")}
      </GenericButton>
    ),
    //eslint-disable-next-line
    []
  );

  const TipsButton = useMemo(
    () => (
      <GenericButton
        onClick={() => {
          dispatch(clearSearchResults());
          !isWaiterOpen && trackAnalyticsEvent("popup-waiterCall");
          dispatch(toggleWaiter());
          localStorage.setItem("waiter-clicked-once", "true");
        }}
        style={{ color: theme.palette.primary.highlightIconFill }}
      >
        <TipsIconStyled />
        {t("BottomNav:tips")}
      </GenericButton>
    ),
    //eslint-disable-next-line
    []
  );

  const getButton = useMemo(() => {
    if (!tips || !tips.allow) return MenuButton;
    return TipsButton;
  }, [tips, MenuButton, TipsButton]);

  return <FixedContainer shift={shift}>{getButton}</FixedContainer>;
};

export default FloatingButton;
