import React from "react";
import {
  WaiterPopupContainerOuter,
  WaiterPopupContainerInner,
  WaiterPopupHeader,
  DefaultOptionsHolder,
  // FallbackText,
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { CartContainerBg } from "../Cart/style";
import { toggleWaiter } from "../../../store/slices/ui.slice";
import { useTranslation } from "react-i18next";
import { defaultArray as defaultOptionsArray } from "../../../interfaces/call-options.interface";
import GenericButton from "../../atoms/GenericButton";
import { useRouteMatch } from "react-router-dom";
import { createRichAlert } from "../../../utils/createRichAlert";
import { menuTableServiceInterface } from "../../../interfaces/menu-table-service.interface";
import { trackAnalyticsEvent } from "../../../utils/trackAnalyticsEvent";
import TipsSection from "../TipsSection";
import {
  clearSearchResults,
  useMenuInteractive,
} from "../../../store/slices/menu.slice";
import { useUi } from "../../../utils/hooks/useUi";
import { useAutoOpenPopup } from "../../../utils/hooks/useAutoOpenPopup";

const WaiterPopup = () => {
  const { isWaiterOpen: isWaiterPopupOpen } = useUi();
  const { t } = useTranslation();

  const DEFAULT_CALL_OPTIONS: typeof defaultOptionsArray = [
    t("WaiterPopup:call"),
    t("WaiterPopup:bill"),
    t("WaiterPopup:payWithCash"),
    t("WaiterPopup:payWithCard"),
  ];

  const dispatch = useDispatch();

  const openPopup = () => {
    dispatch(clearSearchResults());
    !isWaiterPopupOpen && trackAnalyticsEvent("popup-waiterCall");
    dispatch(toggleWaiter());
    localStorage.setItem("waiter-clicked-once", "true");
  };

  useAutoOpenPopup("#tips", openPopup);

  const {
    params: { id },
  }: { params: { id: string } } = useRouteMatch();

  const deviceId = useSelector(
    (state: { userSlice: { deviceId: string } }) => state.userSlice.deviceId
  );

  const dispatchWaiterCall = async (
    option: typeof defaultOptionsArray[number]
  ) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/restaurants/${id}/waiterCall`,
        {
          body: JSON.stringify({
            type: "call",
            message: option,
          }),
          method: "POST",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-Device-Id": deviceId,
          },
        }
      );
      if (res.status === 201) {
        createRichAlert({
          type: "success",
          message: t("WaiterPopup:The waiter is on their way."),
          header: t("WaiterPopup:Okay"),
        });
        trackAnalyticsEvent("waiterCall-used", { event_label: option });
        dispatch(toggleWaiter());
      } else if (res.status === 403) {
        throw new Error(
          t("WaiterPopup:Please rescan the QR code on the table and try again.")
        );
      } else if (res.status === 422) {
        throw new Error(
          t("WaiterPopup:You have made too many calls, please wait a bit.")
        );
      }
    } catch (e: any) {
      createRichAlert({ type: "error", message: e.message });
    }
  };

  const mapOptionsToTranslatedButtons = () => {
    return DEFAULT_CALL_OPTIONS.map((option, index) => {
      return (
        <GenericButton
          onClick={() => dispatchWaiterCall(defaultOptionsArray[index])}
        >
          {option}
        </GenericButton>
      );
    });
  };

  const tableNumber = useSelector(
    (state: {
      menuSlice: {
        response: { interactive: { tableService: menuTableServiceInterface } };
      };
    }) => state.menuSlice?.response?.interactive?.tableService?.table
  );

  const interactive = useMenuInteractive();

  const shouldShowActions =
    !!interactive &&
    !Array.isArray(interactive) &&
    interactive?.tableService?.allowWaiterCall;

  const { tips } = useMenuInteractive();
  const hasBorderBottom = !tips || !tips.allow;

  if (isWaiterPopupOpen) {
    return (
      <>
        <CartContainerBg onClick={() => dispatch(toggleWaiter())} />
        <WaiterPopupContainerOuter>
          <WaiterPopupContainerInner>
            {
              shouldShowActions ? (
                <>
                  <DefaultOptionsHolder>
                    {mapOptionsToTranslatedButtons()}
                  </DefaultOptionsHolder>
                  <WaiterPopupHeader hasBorderBottom={hasBorderBottom}>
                    {t("WaiterPopup:Your table")}: {tableNumber}
                  </WaiterPopupHeader>
                </>
              ) : null
              // <FallbackText>
              //   {t(
              //     "WaiterPopup:The restaurant has not enabled the waiter call"
              //   )}
              // </FallbackText>
            }

            <DefaultOptionsHolder>
              <TipsSection />
            </DefaultOptionsHolder>
          </WaiterPopupContainerInner>
        </WaiterPopupContainerOuter>
      </>
    );
  } else return null;
};

export default WaiterPopup;
