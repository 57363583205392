import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericDialog from "../../components/atoms/GenericDialog";

export const useSuccessMessage = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const currentHash = window.location.hash;

  useEffect(() => {
    if (!currentHash) return;
    if (currentHash.includes("tips-thankyou")) {
      setOpen(true);
      window.location.hash = "";
    }
  }, [currentHash]);

  return (
    <GenericDialog
      setOpen={setOpen}
      open={open}
      title={t("Tips:Thank you for tipping us!")}
      centerContent
    />
  );
};
