import { useSelector } from "react-redux";
import { IUiSlice } from "../../store/slices/ui.slice";

export const useUi = () => {
  const selected = useSelector((state: { uiSlice: IUiSlice }) => state.uiSlice);

  const { isWaiterOpen, isCategorySelectorOpen, isCartOpen, isAboutOpen } =
    selected;
  const isAnyNotSearchOpen =
    isWaiterOpen || isCategorySelectorOpen || isCartOpen || isAboutOpen;
  return { ...selected, isAnyNotSearchOpen };
};
