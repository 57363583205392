import styled from "styled-components";

const FixedContainer = styled.div`
  position: fixed;
  bottom: 85px;
  right: 20px;
  box-shadow: ${({ theme }) => theme.shadow.main};
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  align-items: center;
`;

export { FixedContainer };
