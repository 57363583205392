import useQueryParams from "./useQueryParams";
import { useEffect, useState } from "react";
import { defaultTheme } from "../../themes/default.theme";
import { useSelector } from "react-redux";
import { redTheme } from "../../themes/red.theme";
import { goldTheme } from "../../themes/gold.theme";
import { tealTheme } from "../../themes/teal.theme";
import { classicTheme } from "../../themes/classic.theme";
import { minimalistTheme } from "../../themes/minimalist.theme";
import { oldPaperTheme } from "../../themes/old-paper.theme";

//this is an initial object and it's type declaration to store themes. To add a new one - extend this object
type Themes = {
  default: typeof defaultTheme;
  red: typeof redTheme;
  gold: typeof goldTheme;
  teal: typeof tealTheme;
  classic: typeof classicTheme;
  minimalist: typeof minimalistTheme;
  paper: typeof oldPaperTheme;
};

const themes = {
  default: defaultTheme,
  red: redTheme,
  gold: goldTheme,
  teal: tealTheme,
  minimalist: minimalistTheme,
  classic: classicTheme,
  paper: oldPaperTheme,
};

export const useThemeSetup = () => {
  //get the theme from an api - if any.
  const theme = useSelector(
    (state: { menuSlice: { response: { meta: { theme: string } } } }) =>
      state.menuSlice.response?.meta?.theme
  );
  const queryParams = useQueryParams();

  const queryTheme: keyof Themes | undefined = queryParams.get("theme");

  const [localTheme, setLocalTheme] = useState(defaultTheme);

  //theme handler effect - takes in api response and query params and returns the theme that is intended.
  //the priority is: query params => api response => default fallback
  useEffect(() => {
    if (queryTheme && themes[queryTheme])
      return setLocalTheme(() => themes[queryTheme]);

    if (!theme || theme === "default") return;

    switch (theme) {
      case "classic":
        setLocalTheme(() => themes.classic);
        break;
      case "minimalist":
        setLocalTheme(() => themes.minimalist);
        break;
      case "red":
        setLocalTheme(() => themes.red);
        break;
      case "gold":
        setLocalTheme(() => themes.gold);
        break;
      case "teal":
        setLocalTheme(() => themes.teal);
        break;
      case "paper":
        setLocalTheme(() => themes.paper);
        break;
      default:
        setLocalTheme(() => themes.default);
    }
  }, [theme, queryTheme]);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--bg-style", localTheme.palette.primary.main);
    root.style.setProperty("--font-color", localTheme.palette.common.white);
  }, [localTheme]);

  return localTheme;
};
