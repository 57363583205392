import React from "react";
import { BottomNavContainerInner, BottomNavContainerOuter } from "./styles";
import NavButton from "../../atoms/NavButton";
import { useTranslation } from "react-i18next";
import { ReactComponent as MenuIcon } from "../../../assets/icons/menuIcon.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/searchIcon.svg";
import { ReactComponent as BookmarkIcon } from "../../../assets/icons/bookmark.svg";
import { ReactComponent as BookmarkMarkedIcon } from "../../../assets/icons/bookmarkMarkedAlt.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/infoIcon.svg";
import { ReactComponent as CartIcon } from "../../../assets/icons/cart.svg";
import { ReactComponent as CartFilledIcon } from "../../../assets/icons/cartFilled.svg";
import { ReactComponent as WaiterIcon } from "../../../assets/icons/waiter.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleAbout,
  toggleCart,
  toggleCategories,
  toggleSearch,
  toggleWaiter,
} from "../../../store/slices/ui.slice";
import { useIsInOrderContext } from "../../../utils/hooks/useIsInOrderContext";
import { restartFieldInterface } from "../../../interfaces/restaurant-field.interface";
import { trackAnalyticsEvent } from "../../../utils/trackAnalyticsEvent";
import {
  clearSearchResults,
  useMenuInteractive,
} from "../../../store/slices/menu.slice";
import { useUi } from "../../../utils/hooks/useUi";

const BottomNav = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    isSearchOpen,
    isCartOpen,
    isCategorySelectorOpen,
    isAboutOpen,
    isWaiterOpen,
  } = useUi();

  const { type, about, address, city, country, phone, name } = useSelector(
    (state: {
      menuSlice: { response: { restaurant: restartFieldInterface } };
    }) => state.menuSlice.response?.restaurant || {}
  );

  const shouldShowAbout = !!(
    type ||
    about ||
    address ||
    city ||
    country ||
    phone ||
    name
  );

  const isCartEmpty = !useSelector(
    (state: { menuSlice: { cart: string[] } }) => state.menuSlice.cart
  ).length;

  const isInOrderContext = useIsInOrderContext();
  const interactive = useMenuInteractive();

  const { tableService, tips } = interactive || {};

  const { allowTableOrders, allowWaiterCall } = tableService || {};
  const { allow: allowTips } = tips || {};

  const shouldShowWaiter = !!(
    interactive &&
    (allowWaiterCall || allowTips || allowTableOrders)
  );

  return (
    <BottomNavContainerOuter>
      <BottomNavContainerInner>
        {shouldShowAbout && (
          <NavButton
            isActive={isAboutOpen}
            Icon={InfoIcon}
            onClick={() => {
              dispatch(clearSearchResults());
              !isAboutOpen && trackAnalyticsEvent("popup-about");
              dispatch(toggleAbout());
            }}
          >
            {t("BottomNav:about")}
          </NavButton>
        )}

        <NavButton
          isActive={isCategorySelectorOpen}
          Icon={MenuIcon}
          onClick={() => {
            dispatch(clearSearchResults());
            !isCategorySelectorOpen && trackAnalyticsEvent("popup-toc");
            dispatch(toggleCategories());
          }}
        >
          {t("BottomNav:menu")}
        </NavButton>

        {shouldShowWaiter && (
          <NavButton
            isActive={isWaiterOpen}
            Icon={WaiterIcon}
            hasNewFeatures={!localStorage.getItem("waiter-clicked-once")}
            onClick={() => {
              dispatch(clearSearchResults());
              !isWaiterOpen && trackAnalyticsEvent("popup-waiterCall");
              dispatch(toggleWaiter());
              localStorage.setItem("waiter-clicked-once", "true");
            }}
          >
            {t("BottomNav:waiter")}
          </NavButton>
        )}

        <NavButton
          isActive={isSearchOpen}
          Icon={SearchIcon}
          onClick={() => {
            dispatch(clearSearchResults());
            !isSearchOpen && trackAnalyticsEvent("popup-search");
            dispatch(toggleSearch());
          }}
        >
          {t("search")}
        </NavButton>
        {isInOrderContext ? (
          <NavButton
            isActive={isCartOpen}
            Icon={isCartEmpty ? CartIcon : CartFilledIcon}
            onClick={() => {
              dispatch(clearSearchResults());
              !isCartOpen && trackAnalyticsEvent("popup-cart");
              dispatch(toggleCart());
            }}
          >
            {t("BottomNav:cart")}
          </NavButton>
        ) : (
          <NavButton
            isActive={isCartOpen}
            Icon={isCartEmpty ? BookmarkIcon : BookmarkMarkedIcon}
            onClick={() => {
              dispatch(clearSearchResults());
              !isCartOpen && trackAnalyticsEvent("popup-shortlist");
              dispatch(toggleCart());
            }}
          >
            {t("BottomNav:my picks")}
          </NavButton>
        )}
      </BottomNavContainerInner>
    </BottomNavContainerOuter>
  );
};

export default BottomNav;
