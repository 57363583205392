import { useSelector } from "react-redux";
import { IMenuSlice } from "../../store/slices/menu.slice";

export const useMenuSlice = () => {
  return useSelector((state: { menuSlice: IMenuSlice }) => state.menuSlice);
};

export const useMenuApiResponse = () => {
  return useMenuSlice().response!;
};
