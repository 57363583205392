import React, { FC, useEffect } from "react";
import { AlertInterface } from "./interface";
import { AlertContainer, AlertHeader, AlertInner, AlertText } from "./styles";
import Warning from "../../../assets/icons/warning.svg";
import Error from "../../../assets/icons/error.svg";
import Success from "../../../assets/icons/checkmark-light.svg";
import { unmountComponentAtNode } from "react-dom";

const Alert: FC<AlertInterface> = ({
  type,
  autoHideDuration = 3000,
  message,
  header,
}) => {
  const selectAlertIcon = () => {
    switch (type) {
      case "error":
        return <img alt="" src={Error} />;
      case "warning":
        return <img alt="" src={Warning} />;
      case "success":
        return <img alt="" src={Success} />;
      default:
        return;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById("alertContainer");
      if (el) {
        unmountComponentAtNode(el);
        document.body.removeChild(el);
      }
    }, autoHideDuration);
    //eslint-disable-next-line
  }, []);
  return (
    <AlertContainer type={type}>
      {selectAlertIcon()}
      <AlertInner>
        <AlertHeader>{header || type}!</AlertHeader>
        <AlertText>{message}</AlertText>
      </AlertInner>
    </AlertContainer>
  );
};

export default Alert;
