import styled, { keyframes } from "styled-components";

const slideOut = keyframes`
  0%{transform: scaleY(0)}
  100%{transform: scaleY(1)}
`;

const CartContainerBg = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  object-fit: cover;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: black;
  opacity: 0.7;
  overflow: hidden;
  z-index: 10;
`;

const CartContainerOuter = styled.div`
  position: sticky;
  bottom: 55px;
  width: 100%;
  background: ${({ theme }) => theme.palette.primary.headerFooter};
  border-top: 2px solid ${({ theme }) => theme.palette.primary.accentBorder};
  border-radius: ${({ theme }) => theme.borders.bottomSheets};
  box-shadow: ${({ theme }) => theme.shadow.mainUp};
  z-index: 97;
  user-select: none;
  min-height: 15vh;
  max-height: 60vh;
  transform-origin: bottom center;
  animation: ${slideOut} 0.3s ease-in-out;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    @media screen and (max-width: 768px) {
      width: 0;
    }
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.primary.accent};
    border-radius: ${({ theme }) => theme.borders.generalRadius};
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.primary.elevation1};
    border-radius: ${({ theme }) => theme.borders.generalRadius};
  }
`;

const CartContainerInner = styled.div<{ isOneColumn: boolean }>`
  ${({ theme }) => theme.container};
  height: 100%;
  max-height: 100%;
  margin-top: 20px;
  padding-bottom: 60px;
  overflow-y: auto;
  display: grid;
  align-items: start;
  grid-template-columns: ${({ isOneColumn }) =>
    isOneColumn ? "1fr" : "5fr 1fr"};
  gap: 20px;
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const CartItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export {
  CartContainerOuter,
  CartContainerInner,
  CartContainerBg,
  CartItemsContainer,
};
