import styled, { css } from "styled-components";
import { CornerLabelInterface } from "./interface";

const ActiveStyles = css`
  background: ${({ theme }) => theme.palette.primary.accent};
  border: 2px solid transparent;

  & svg path {
    fill: ${({ theme }) => theme.palette.primary.highlightIconFill};
  }
`;

const InactiveStyles = css`
  background: ${({ theme }) => theme.palette.primary.elevation1};
  border: 2px solid ${({ theme }) => theme.palette.primary.accentBorder};
`;

const CornerLabelContainer = styled.div<CornerLabelInterface>`
  ${({ isActive }) => (isActive ? ActiveStyles : InactiveStyles)};
  ${({ position }) => position && position.split("-").join(" 0;")};
  cursor: ${({ onClick }) => (onClick ? "pointer" : "auto")};
  position: absolute;
  padding: 4px 8px;
  & svg,
  img {
    display: block;
    height: 15px;
    width: auto;
  }
  ${({ theme }) =>
    theme.cornerLabelStyles
      ? theme.cornerLabelStyles
      : css`
          &.topLeft {
            top: -2px;
            left: -2px;
            border-bottom-right-radius: ${({ theme }) =>
              theme.borders.generalRadius};
            border-top-left-radius: ${({ theme }) =>
              theme.borders.generalRadius};
          }
          &.topRight {
            top: -2px;
            right: -2px;
            border-bottom-left-radius: ${({ theme }) =>
              theme.borders.generalRadius};
            border-top-right-radius: ${({ theme }) =>
              theme.borders.generalRadius};
          }
          &.bottomRight {
            bottom: -2px;
            right: -2px;
            border-top-left-radius: ${({ theme }) =>
              theme.borders.generalRadius};
            border-bottom-right-radius: ${({ theme }) =>
              theme.borders.generalRadius};
          }
          &.bottomLeft {
            bottom: -2px;
            left: -2px;
            border-bottom-left-radius: ${({ theme }) =>
              theme.borders.generalRadius};
            border-top-right-radius: ${({ theme }) =>
              theme.borders.generalRadius};
          }
        `}
`;

export { CornerLabelContainer };
