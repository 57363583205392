import React, { FC, useMemo, useRef } from "react";
import { menuCategoryInterface } from "../../../interfaces/menu-category.interface";
import {
  CategoryContainer,
  CategoryDescription,
  CategoryHeader,
  CategoryHeaderContainer,
  CategoryTextContainer,
  ItemsContainer,
} from "./styles";
import ItemCard from "../../molecules/ItemCard";
import StackGrid from "react-stack-grid";
import sizeMe from "react-sizeme";
import ReactMarkdown from "react-markdown";
import { useTheme } from "styled-components";
import { menuRenderer } from "../../../utils/menuRenderer";

interface MenuCategoryInterface extends menuCategoryInterface {
  id: string;
}

const MenuCategory: FC<MenuCategoryInterface> = ({
  name,
  description,
  items,
  id,
  menus,
  nestLevel,
  // @ts-ignore
  size: { width, height },
}) => {
  const gridRef = useRef<any>(null);

  const createCards = useMemo(() => {
    if (items && items.length) {
      const _items = [...items];

      const _disabled = _items.filter((item) => !item.enabled);
      const _enabled = _items.filter((item) => item.enabled);

      const __items = [..._enabled, ..._disabled];

      return __items.map((item) => {
        return (
          <ItemCard {...item} key={item.id} id={item.id} gridRef={gridRef} />
        );
      });
    }
  }, [items]);

  const onLayout = () =>
    setTimeout(
      () =>
        document
          .querySelectorAll(".stg")
          .forEach((el) => el.classList.add("noMinHeight")),
      100
    );

  const theme: any = useTheme();

  const createLayout = useMemo(() => {
    if (items && items.length && width > 600) {
      return (
        <StackGrid
          columnWidth={theme.desktopColumnsWidth || 300}
          gutterWidth={10}
          gutterHeight={10}
          duration={300}
          className="stg"
          style={{ position: "relative", minHeight: 1000 }}
          onLayout={onLayout}
          monitorImagesLoaded //makes for shit performance tbh, but this is required for the cards to get correct positioning
          ref={gridRef}
        >
          {createCards}
        </StackGrid>
      );
    } else if (items && items.length && width <= 600) {
      return <ItemsContainer>{createCards}</ItemsContainer>;
    } else return null;
    //eslint-disable-next-line
  }, [items]);

  const HeaderDecoration = theme.categoryHeaderDecoration;

  return (
    <>
      <CategoryContainer id={id}>
        <CategoryHeaderContainer
          shouldJustify={!!HeaderDecoration}
          height={height}
        >
          <CategoryTextContainer>
            <CategoryHeader nestLevel={nestLevel}>
              <ReactMarkdown>{name}</ReactMarkdown>
            </CategoryHeader>
            {!theme?.prependDescriptionsToCards ? (
              <CategoryDescription>
                <ReactMarkdown>{description}</ReactMarkdown>
              </CategoryDescription>
            ) : null}
          </CategoryTextContainer>
          {HeaderDecoration ? <HeaderDecoration /> : null}
        </CategoryHeaderContainer>

        {theme?.prependDescriptionsToCards ? (
          <CategoryDescription>
            <ReactMarkdown>{description}</ReactMarkdown>
          </CategoryDescription>
        ) : null}
        {createLayout}
      </CategoryContainer>
      {menus ? menuRenderer(menus) : null}
    </>
  );
};

export default React.memo(sizeMe({ monitorHeight: true })(MenuCategory));
