import styled, { css } from "styled-components";

const defaultChipStyles = css`
  padding: 10px 20px;
  border: 2px solid ${({ theme }) => theme.palette.primary.accentBorder};
  border-radius: 100px;
  cursor: pointer;
`;

const activeChipStyles = css`
  background: ${({ theme }) => theme.palette.primary.accent};
  color: ${({ theme }) => theme.palette.common.black};
`;

const ChipContainer = styled.div<{ active: boolean }>`
  ${defaultChipStyles}
  ${({ active }) => (active ? activeChipStyles : "")}
`;

export { ChipContainer };
