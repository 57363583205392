import { jsonResponseInterface } from "../interfaces/json-response.interface";
import { menuCategoryInterface } from "../interfaces/menu-category.interface";

export const findDeepNested = (
  input: jsonResponseInterface | menuCategoryInterface[],
  keyToFind: string,
  valToFind: string | number
): any => {
  let foundObj;
  JSON.stringify(input, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj as any;
};
