import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { GenericInputInterface } from "./interface";
import { InputElement, InputIcon, InputLabel, InputWrapper } from "./styles";

const GenericInput: FC<GenericInputInterface> = ({
  label,
  onChange,
  id,
  inputIcon,
  name,
  defaultValue,
  variant = "full",
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange && onChange(e);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <InputWrapper>
      {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
      <InputElement
        id={id}
        {...rest}
        onChange={onInputChange}
        value={value}
        variant={variant}
      />
      {inputIcon && <InputIcon src={inputIcon} alt={name} />}
    </InputWrapper>
  );
};

export default GenericInput;
