import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { menuItemInterface } from "../../../interfaces/menu-item.interface";
import {
  Card,
  CardContent,
  CardContentInner,
  CardDescription,
  CardHeader,
  CardImage,
  CardInner,
  CardTopContent,
  PriceAmount,
  PriceContainer,
  PriceItem,
  PriceLabel,
} from "./styles";
import CornerLabel from "../../atoms/CornerLabel";
import { ReactComponent as Bookmark } from "../../../assets/icons/bookmark.svg";
import { ReactComponent as BookmarkMarked } from "../../../assets/icons/bookmarkMarked.svg";
import { useDispatch, useSelector } from "react-redux";
import { cartItem, toggleInCart } from "../../../store/slices/menu.slice";
import { useIsInOrderContext } from "../../../utils/hooks/useIsInOrderContext";
import { ReactComponent as CartIcon } from "../../../assets/icons/cart.svg";
import { ReactComponent as CartFilledIcon } from "../../../assets/icons/cartFilled.svg";
import { trackAnalyticsEvent } from "../../../utils/trackAnalyticsEvent";
import LazyLoad from "react-lazyload";
import { forceCheck } from "react-lazyload";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import ReactMarkdown from "react-markdown";

const ItemCard: FC<menuItemInterface> = ({
  description,
  title,
  enabled,
  prices,
  theimgurl,
  id: itemId,
  imgurl,
  gridRef,
  hideItem,
}) => {
  const dispatch = useDispatch();

  const isActive = useSelector(
    (state: { menuSlice: { cart: cartItem[] } }) => state.menuSlice.cart
  ).some((i) => i.id === itemId.toString());

  const cartItems = useSelector(
    (state: { menuSlice: { cart: cartItem[] } }) => state.menuSlice.cart
  );

  const shouldForceUpdate = useSelector(
    (state: { menuSlice: { searchKey: string | null } }) =>
      state.menuSlice.searchKey
  );

  useEffect(() => {
    if (shouldForceUpdate) {
      forceCheck();
    }
  }, [shouldForceUpdate]);

  const renderPrices = useMemo(() => {
    return prices.map((price) => {
      const { label, amount, id: priceId } = price;
      return (
        <PriceItem
          key={`price-${Math.round(Math.random() * 100000)}`}
          isActive={cartItems.some(
            (i) =>
              i.id.split("-")[0] === itemId.toString() && i.priceId === priceId
          )}
          onClick={(e) => {
            toggleActive(e, priceId);
          }}
        >
          {label && <PriceLabel>{label.trim()}</PriceLabel>}

          <PriceAmount>{amount}</PriceAmount>
        </PriceItem>
      );
    });
    //eslint-disable-next-line
  }, [prices, cartItems]);

  const toggleActive = (
    e: SyntheticEvent,
    //if no price provided - get the default one - the first one from the prices array
    priceId: number = prices[0].id
  ) => {
    //stopPropagation() is here to stop the event from bubbling to the parent card if the price is clicked
    e.stopPropagation();
    //execute this only if the item is not disabled
    const { amount, label } = prices.find((p) => p.id === priceId)!;
    if (enabled) {
      dispatch(
        toggleInCart({
          id: itemId.toString(),
          priceId,
          amount: parseFloat(amount),
          quantity: 1,
          label,
          itemName: title,
        })
      );
    }
    if (!isActive) {
      trackAnalyticsEvent("shortlist-used", {
        event_label: `${title} (${
          prices.find((p) => p.id === priceId)?.label
        }) ${amount}`,
      });
    }
  };

  const isInOrderContext = useIsInOrderContext();

  const renderIcon = () => {
    if (isActive) {
      if (isInOrderContext) {
        return <CartFilledIcon />;
      } else {
        return <BookmarkMarked />;
      }
    } else {
      if (isInOrderContext) {
        return <CartIcon />;
      } else {
        return <Bookmark />;
      }
    }
  };

  const [hideImage, setHideImage] = useState(false);
  const windowWidth = useWindowSize();

  const imageRef = useCallback((node) => {
    if (node !== null && theimgurl) {
      const onSecondError = () => {
        setHideImage(true);
        if (windowWidth > 600) {
          gridRef?.current?.updateLayout();
        }
      };
      const onError = () => {
        node.src = imgurl;
        node.onerror = null;
        node.onerror = onSecondError;
      };

      node.onerror = onError;
    }
    //eslint-disable-next-line
  }, []);

  return useMemo(
    () => (
      <Card enabled={!!enabled} isActive={isActive} hide={hideItem}>
        {!!enabled && (
          <CornerLabel
            isActive={isActive}
            position="topRight"
            onClick={(e) => toggleActive(e)}
          >
            {renderIcon()}
          </CornerLabel>
        )}
        <CardInner hasImage={!!(theimgurl && !hideImage)}>
          {theimgurl && !hideImage && (
            <CardImage hide={hideImage}>
              <LazyLoad height={150} once offset={50} unmountIfInvisible>
                <img
                  src={theimgurl}
                  alt={title}
                  loading="lazy"
                  ref={imageRef}
                  height={150}
                />
              </LazyLoad>
            </CardImage>
          )}
          <CardContentInner>
            <CardContent>
              <CardTopContent>
                <CardHeader fullWidth={!!theimgurl && !hideImage}>
                  <ReactMarkdown>{title}</ReactMarkdown>
                </CardHeader>
                <CardDescription>
                  <ReactMarkdown>{description}</ReactMarkdown>
                </CardDescription>
              </CardTopContent>
            </CardContent>
            <PriceContainer>{renderPrices}</PriceContainer>
          </CardContentInner>
        </CardInner>
      </Card>
    ),
    //eslint-disable-next-line
    [isActive, hideImage, hideItem, renderPrices]
  );
};

export default React.memo(ItemCard);
