import styled from "styled-components";

const BottomNavContainerOuter = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.primary.headerFooter};
  border-top: 2px solid ${({ theme }) => theme.palette.primary.accentBorder};
  border-radius: ${({ theme }) => theme.borders.bottomSheets};
  box-shadow: ${({ theme }) => theme.shadow.mainUp};
  height: 65px;
  position: sticky;
  z-index: 99;
  bottom: 0;
`;
const BottomNavContainerInner = styled.div`
  ${({ theme }) => theme.container};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  & > button:not(:last-child) {
    margin-right: 10px;
  }
`;

export { BottomNavContainerInner, BottomNavContainerOuter };
