import React, { FC, useEffect } from "react";
import { GenericDialogInterface } from "./interface";
import {
  BackDrop,
  DialogHolder,
  DialogTitle,
  DialogContent,
  DialogCloseButton,
} from "./styles";
import { scrollManager } from "../../../utils/scrollManager";
import GenericButton from "../GenericButton";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";

const GenericDialog: FC<GenericDialogInterface> = ({
  open,
  title,
  children,
  centerContent,
  setOpen,
}) => {
  useEffect(() => {
    if (open) {
      scrollManager.disableScroll();
    } else {
      scrollManager.enableScroll();
    }
  }, [open]);

  return open ? (
    <BackDrop onClick={() => setOpen(false)}>
      <DialogHolder centerContent={centerContent}>
        <DialogCloseButton onClick={() => setOpen(false)}>
          <GenericButton square>
            <CrossIcon />
          </GenericButton>
        </DialogCloseButton>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
      </DialogHolder>
    </BackDrop>
  ) : null;
};

export default GenericDialog;
