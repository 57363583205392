import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEng from "./locales/en";
import translationUa from "./locales/ua";
import translationFr from "./locales/fr";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      caches: [],
    },
    resources: {
      //if new languages are needed - just add those here.
      //IMPORTANT - ADD THE NEW LANGS TO THE PARSER CONFIG
      en: {
        translations: translationEng,
      },
      uk: {
        translations: translationUa,
      },
      fr: {
        translations: translationFr,
      },
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    saveMissing: true,

    keySeparator: ":",

    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },

    react: {
      useSuspense: true,
    },
  });

export default i18n;
