import { css } from "styled-components";
import goldBg from "../assets/goldmarbelbg.jpg";

export const goldTheme = {
  typography: {
    fontFamily: "Montserrat",
    isCustomFontFamily: true,
    headingsFontFamily: "Klapt Bold",
    headingsFontFamilyType: "sans-serif",
    fontSizes: {
      small: 12,
      mediumSmall: 14,
      medium: 16,
      extraMedium: 19,
      large: 24,
      extraLarge: "3em",
    },
    fontWeights: {
      lighter: 400,
      bold: 600,
      medium: 500,
      extraBold: 700,
    },
  },
  shadow: {
    main: `0 0.2px 0.6px rgba(0, 0, 0, 0.011),
        0 0.6px 1.5px rgba(0, 0, 0, 0.016),
        0 1.1px 2.8px rgba(0, 0, 0, 0.02),
        0 2px 4.9px rgba(0, 0, 0, 0.024),
        0 3.8px 9.2px rgba(0, 0, 0, 0.029),
         0 9px 22px rgba(0, 0, 0, 0.04)`,

    mainUp: `0 -0.2px 0.6px rgba(0, 0, 0, 0.011),
        0 -0.6px 1.5px rgba(0, 0, 0, 0.016),
        0 -1.1px 2.8px rgba(0, 0, 0, 0.02),
        0 -2px 4.9px rgba(0, 0, 0, 0.024),
        0 -3.8px 9.2px rgba(0, 0, 0, 0.029),
         0 -9px 22px rgba(0, 0, 0, 0.04)`,
    large: `0 0.6px 1.1px rgba(0, 0, 0, 0.011),
  0 1.3px 2.5px rgba(0, 0, 0, 0.016),
  0 2.5px 4.8px rgba(0, 0, 0, 0.02),
  0 4.5px 8.5px rgba(0, 0, 0, 0.024),
  0 8.4px 15.9px rgba(0, 0, 0, 0.029),
  0 20px 38px rgba(0, 0, 0, 0.04)
`,
  },
  palette: {
    common: {
      white: "#fff",
      black: "#000",
    },
    primary: {
      main: `url(${goldBg}) repeat top center / contain #121418`,
      headerFooter: `url(${goldBg}) no-repeat top center / cover #121418`,
      highlightIconFill: "#fff",
      accent: "#BD9456",
      accentBorder: "#BD9456",
      elevation: "#121418",
      elevation1: "#402E32",
    },
    custom: {
      successGreen: "#4cd964",
      dangerRed: "#d0021b",
      warningYellow: "#ffcc00",
    },
  },
  borders: {
    generalRadius: "0",
    small: "0",
    medium: "0",
    extraMedium: "0",
    topSheets: "0",
    bottomSheets: "0",
  },
  container: css`
    width: 90%;
    max-width: 1300px;
    margin: auto;
  `,
  categoryHeadings: css`
    line-height: 0.9;
    font-size: 34px;
  `,
  cardProps: {
    padding: "4px",
    border: (isActive: boolean) => css`
      border: ${({ theme }) =>
        isActive
          ? `2px solid ${theme.palette.primary.accentBorder}`
          : "2px solid transparent"};
      border-top-color: ${({ theme }) => theme.palette.primary.accentBorder};
    `,
    inactiveStyles: css`
      filter: grayscale(1);
      opacity: 0.9;
    `,
  },
  cardLayout: (hasImage: boolean) => css`
    display: block;
  `,
};
