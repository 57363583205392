import React, { ComponentType, useEffect, useState } from "react";
import Header from "../../molecules/Header";
import SearchBar from "../../organisms/SearchBar";
import Cart from "../../organisms/Cart";
import CategoryListView from "../../organisms/CategoryListView";
import About from "../../organisms/About";
import BottomNav from "../../molecules/BottomNav";
import { AppContainerInner } from "../styles";
import { useFetchMenuOrCache } from "../../../utils/hooks/useFetchMenuOrCache";
import { useParamsAndTrackEvent } from "../../../utils/hooks/useGetParamsAndTrackEvent";
import SplashScreen from "../../molecules/SplashScreen";
import { useDispatch, useSelector } from "react-redux";
import { menuCategoryInterface } from "../../../interfaces/menu-category.interface";
import { restartFieldInterface } from "../../../interfaces/restaurant-field.interface";
import ErrorPage from "../../screens/404";
import { Helmet } from "react-helmet-async";
import { getRandomImage } from "../../../utils/getRandomImage";
import { useFontLink } from "../../../utils/hooks/useAdditionalFonts";
import { useTheme } from "styled-components";
import WaiterPopup from "../../organisms/WaiterPopup";
import { setCachedCart } from "../../../store/slices/menu.slice";
import BottomActions from "../../organisms/BottomActions";

const MenuLayout: ComponentType = ({ children }) => {
  const { name, logo } = useFetchMenuOrCache();
  useParamsAndTrackEvent();
  const { menu, restaurant } = useSelector(
    (state: {
      menuSlice: {
        response: {
          menu: menuCategoryInterface[];
          restaurant: restartFieldInterface;
        };
        searchResults: menuCategoryInterface[];
      };
    }) => state.menuSlice.response || {}
  );

  const { error: menuError, loading: menuLoading } = useSelector(
    (state: { menuSlice: { error: string; loading: boolean } }) =>
      state.menuSlice
  );

  const [showSplash, setShowSplash] = useState(true);
  const [shouldStart, setShouldStart] = useState(false);

  const ogImage = getRandomImage(menu);
  const dispatch = useDispatch();

  useEffect(() => {
    if ((menu && menu.length) || restaurant?.id) {
      document.title = restaurant?.name + " - QRMenu.biz";
      setShouldStart(true);
      dispatch(setCachedCart());
      setTimeout(() => {
        setShowSplash(false);
      }, 1500);
    } else if (menuError) {
      setShowSplash(false);
    }
    //eslint-disable-next-line
  }, [menu, menuError]);

  const fontLink = useFontLink();
  //@ts-ignore
  const isCustomFont = useTheme().typography?.isCustomFontFamily;

  return (
    <>
      {showSplash && <SplashScreen shouldStart={shouldStart} />}
      {!menuLoading && !menuError ? (
        <>
          <Helmet>
            {!isCustomFont && !fontLink.includes("Montserrat") && (
              <>
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link href={fontLink} rel="stylesheet" />
              </>
            )}
            <meta name="description" content={restaurant?.about} />

            <meta property="og:type" content="website" />
            <meta property="og:url" content={process.env.REACT_APP_SITE_URL} />
            <meta
              property="og:title"
              content={restaurant?.name + " - QRMenu.biz"}
            />
            <meta property="og:description" content={restaurant?.about} />
            <meta property="og:image" content={ogImage} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta
              property="twitter:url"
              content={process.env.REACT_APP_SITE_URL}
            />
            <meta
              property="twitter:title"
              content={restaurant?.name + " - QRMenu.biz"}
            />
            <meta property="twitter:description" content={restaurant?.about} />
            <meta property="twitter:image" content={ogImage} />
          </Helmet>
          <Header name={name} logo={logo} />
          <AppContainerInner id="searchDiv">{children}</AppContainerInner>
          <BottomActions />
          <SearchBar />
          <Cart />
          <CategoryListView />
          <About />
          <WaiterPopup />
          <BottomNav />
        </>
      ) : (
        <ErrorPage />
      )}
    </>
  );
};

export default MenuLayout;
