import useQueryParams from "./useQueryParams";
import { useEffect } from "react";
import i18n from "i18next";

export const useLangSetup = () => {
  const queryParams = useQueryParams();
  const lang: string | undefined = queryParams.get("lang");
  const responseLang = navigator.languages[0];

  //lang change handler. in query params the lang should be specified as in the i18n.ts resources objects.
  //the hook will rerun on lang update
  useEffect(() => {
    if (lang || responseLang) {
      i18n.changeLanguage(lang || responseLang);
    }
  }, [lang, responseLang]);
};
