import styled from "styled-components";
import QrBg from "../../../assets/qrbg.svg";

const PageHolder = styled.div`
  background: url(${QrBg}) center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  padding: 10px;
`;

const PageIllustration = styled.img`
  max-width: 100%;
`;

const PageText = styled.h1`
  text-align: center;
`;

export { PageHolder, ContentContainer, PageIllustration, PageText };
