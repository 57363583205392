import styled, { css } from "styled-components";
import { GenericButtonInterface } from "./interface";

interface ButtonStylesInterface extends Partial<GenericButtonInterface> {}

const squareButtonStyles = css`
  width: 35px;
  height: 35px;
  & img {
    width: 15px;
    height: 15px;
  }
`;
const regularButtonStyles = css`
  height: 35px;
  padding: 0 20px;
`;

const primaryButtonStyles = css`
  background: ${({ theme }) => theme.palette.primary.accent};
  color: ${({ theme }) => theme.palette.common.black};
`;
const secondaryButtonStyles = css<Pick<GenericButtonInterface, "color">>`
  background: ${({ theme, color }) =>
    color === "secondaryWithBackground"
      ? theme.palette.primary.elevation1
      : "transparent"};
  border: 2px solid ${({ theme }) => theme.palette.primary.accentBorder};
  color: ${({ theme }) => theme.palette.common.white};
`;

const commonButtonStyles = css<
  Pick<GenericButtonInterface, "margins" | "paddings">
>`
  border: none;
  outline: none;
  border-radius: ${({ theme }) => theme.borders.small};
  margin: ${({ margins }) =>
    margins ? margins.map((m) => m + "px").join(" ") : 0};
  padding: ${({ paddings }) => (paddings ? paddings.join(" ") + "px" : 0)};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

const smallButtonStyles = css`
  width: 30px;
  height: 30px;
`;

const ButtonStyled = styled.button<ButtonStylesInterface>`
  ${commonButtonStyles}
  ${({ square }) => (square ? squareButtonStyles : regularButtonStyles)}
  ${({ color }) =>
    color === "primary" ? primaryButtonStyles : secondaryButtonStyles}
  ${({ small }) => (small ? smallButtonStyles : "")}
`;

export { ButtonStyled };
