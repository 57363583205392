import styled from "styled-components";

const CartSummaryContainer = styled.div`
  border-radius: ${({ theme }) => theme.borders.generalRadius};
  border: 2px solid ${({ theme }) => theme.palette.primary.accentBorder};
  background: ${({ theme }) => theme.palette.primary.elevation1};
  box-shadow: ${({ theme }) => theme.shadow.main};
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CartSummaryHeader = styled.h2``;

const SmallText = styled.p`
  font-size: 12px;
`;

const CartSummaryTotal = styled.h1`
  margin-top: 10px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export { CartSummaryContainer, CartSummaryHeader, CartSummaryTotal, SmallText };
