import styled, { keyframes } from "styled-components";

const slideOut = keyframes`
  0%{transform: scaleY(0)}
  100%{transform: scaleY(1)}
`;
const SearchContainerOuter = styled.div`
  position: sticky;
  bottom: 65px;
  width: 100%;
  background: ${({ theme }) => theme.palette.primary.headerFooter};
  border-top: 2px solid ${({ theme }) => theme.palette.primary.accentBorder};
  border-radius: ${({ theme }) => theme.borders.bottomSheets};
  box-shadow: ${({ theme }) => theme.shadow.mainUp};
  min-height: 65px;
  z-index: 98;
  transform-origin: bottom center;
  animation: ${slideOut} 0.3s ease-in-out;
`;

const SearchContainerInner = styled.div`
  ${({ theme }) => theme.container};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${({ theme }) => theme.palette.common.white};
  padding: 15px 0 35px 0;
`;

export { SearchContainerOuter, SearchContainerInner };
