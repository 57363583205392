import React, { useState } from "react";
import { FixedContainer } from "./styles";
import FloatingButton from "../../molecules/FloatingButton";
import UpButton from "../../molecules/UpButton";

const BottomActions = () => {
  const [shift, setShift] = useState(false);
  return (
    <FixedContainer>
      <FloatingButton shift={shift} />
      <UpButton setShift={setShift} />
    </FixedContainer>
  );
};

export default BottomActions;
