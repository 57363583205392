import React, { FC, useState } from "react";
import { IChip } from "./interface";
import { ChipContainer } from "./styles";

const Chip: FC<IChip> = ({ selectable, onClick, value, children }) => {
  const [active, setActive] = useState(false);

  const onChipClick = () => {
    onClick && onClick(value);
    if (!selectable) return;
    setActive((a) => !a);
  };

  return (
    <ChipContainer active={active} onClick={onChipClick}>
      {children}
    </ChipContainer>
  );
};

export default Chip;
