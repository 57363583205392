import React, { FC } from "react";
import { HeaderInterface } from "./interface";
import {
  HeaderContainerInner,
  HeaderContainerOuter,
  HeaderLogo,
  HeaderName,
} from "./styles";

const Header: FC<HeaderInterface> = ({ logo, name }) => {
  return (
    <HeaderContainerOuter>
      <HeaderContainerInner>
        {logo ? (
          <HeaderLogo src={logo} alt={name} />
        ) : (
          <HeaderName>{name}</HeaderName>
        )}
      </HeaderContainerInner>
    </HeaderContainerOuter>
  );
};

export default Header;
