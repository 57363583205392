import React from "react";
import {
  ContentContainer,
  PageHolder,
  PageIllustration,
  PageText,
} from "./styles";

import ErrorIllustration from "../../../assets/404.svg";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <PageHolder>
      <ContentContainer>
        <PageIllustration src={ErrorIllustration} alt="page not found" />
        <PageText>{t("ErrorPage:looks like you got lost... 😭")}</PageText>
      </ContentContainer>
    </PageHolder>
  );
};

export default ErrorPage;
