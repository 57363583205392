import { validateCache } from "./validateCache";
import { cartItem } from "../../store/slices/menu.slice";

//takes in an id as cart-<restaurantMenuId> to only target the correct restaurant
export const cacheCart = (id: string, items?: cartItem[]) => {
  if (validateCache(id)) {
    return JSON.parse(window.sessionStorage.getItem(id)!);
  } else if (items) {
    window.sessionStorage.setItem(id, JSON.stringify(items));
    return items;
  } else return [];
};

export const clearCartCache = (id: string) => {
  if (validateCache(id)) {
    window.sessionStorage.removeItem(id);
  }
};
