import { useEffect } from "react";

export const useAutoOpenPopup = (hash: string, open: () => void) => {
  useEffect(() => {
    if (window.location.hash === hash) {
      open();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
