import styled from "styled-components";
import { createFontFamily } from "../../../utils/hooks/useAdditionalFonts";

const HeaderContainerOuter = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.primary.headerFooter};
  border-bottom: 2px solid ${({ theme }) => theme.palette.primary.accentBorder};
  border-radius: ${({ theme }) => theme.borders.topSheets};
  box-shadow: ${({ theme }) => theme.shadow.main};
  max-height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HeaderContainerInner = styled.div`
  ${({ theme }) => theme.container};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100%;
  text-align: center;
`;

const HeaderLogo = styled.img`
  max-height: 90px;
`;

const HeaderName = styled.h1`
  font-family: ${({ theme }) => createFontFamily(theme)};
  font-weight: 900;
  @media screen and (max-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
`;

export { HeaderContainerOuter, HeaderContainerInner, HeaderLogo, HeaderName };
