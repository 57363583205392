import React, { FC } from "react";
import { GenericButtonInterface } from "./interface";
import { ButtonStyled } from "./styles";

const GenericButton: FC<GenericButtonInterface> = ({
  width,
  height,
  color = "primary",
  children,
  square = false,
  margins,
  paddings,
  small,
  ...rest
}) => {
  return (
    <ButtonStyled
      square={square}
      width={width}
      height={height}
      color={color}
      margins={margins}
      paddings={paddings}
      small={small}
      {...rest}
    >
      {children}
    </ButtonStyled>
  );
};

export default GenericButton;
