import React from "react";
import { FooterHeader, FooterHolder, FooterText } from "./styles";
import { useSelector } from "react-redux";
import { restartFieldInterface } from "../../../interfaces/restaurant-field.interface";
import { useTranslation } from "react-i18next";

const CopyrightFooter = () => {
  const restaurant = useSelector(
    (state: {
      menuSlice: { response: { restaurant: restartFieldInterface } };
    }) => state.menuSlice.response?.restaurant
  );
  const { t } = useTranslation();

  return (
    <FooterHolder>
      <FooterHeader>
        {restaurant?.name} ❤{" "}
        <a
          href={process.env.REACT_APP_SITE_URL}
          target="_blank"
          rel="noopener, noreferrer"
        >
          QRmenu.biz
        </a>
      </FooterHeader>
      <FooterText>
        {t("CopyrightFooter:menu provided by the restaurant")}
      </FooterText>
    </FooterHolder>
  );
};

export default CopyrightFooter;
