import styled from "styled-components";

const Card = styled.div<{
  enabled: boolean;
  isActive: boolean;
  hide?: boolean;
}>`
  display: ${({ hide }) => (hide ? "none" : "block")};
  background: ${({ theme }) => theme.palette.primary.elevation};
  box-shadow: ${({ theme }) => theme.shadow.main};
  border-radius: ${({ theme }) => theme.borders.generalRadius};
  ${({ theme, isActive }) => theme.cardProps.border(isActive)};
  transition: all ease-in-out 0.3s;
  position: relative;
  color: ${({ theme }) =>
    theme.cardProps.fontColor ? theme.cardProps.fontColor : "inherit"};
  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.large};
  }
  width: 100%;
  cursor: ${({ enabled }) => (enabled ? "pointer" : "auto")};
  ${({ theme, enabled }) => (enabled ? "" : theme.cardProps.inactiveStyles)}
`;

const CardImage = styled.div<{ hide: boolean }>`
  & img {
    border-radius: ${({ theme }) => theme.borders.extraMedium};
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
`;

const CardContent = styled.div`
  padding: 10px;
  font-size: 14px;
  & * {
    margin-top: 10px;
    white-space: pre-line;
  }
`;

const CardHeader = styled.h3<{ fullWidth?: boolean }>`
  margin-bottom: 5px;
  max-width: ${({ fullWidth }) => (fullWidth ? "100%" : "250px")};
`;

const CardDescription = styled.p``;

const PriceContainer = styled.div`
  justify-self: flex-end;
  border-radius: ${({ theme }) => theme.borders.medium};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 50px;
  overflow: auto;
  max-width: 100%;
  ::-webkit-scrollbar {
    height: 0;
  }
`;

const PriceItem = styled.div<{ isActive?: boolean }>`
  border: ${({ theme, isActive }) =>
    isActive
      ? `2px solid ${theme.palette.primary.accentBorder}`
      : "2px solid transparent"};
  transition: all ease-in-out 0.3s;
  text-align: center;
  background: ${({ theme }) => theme.palette.primary.elevation1};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borders.medium};
  width: 100%;
  min-width: 70px;
  height: 100%;
  &:not(:last-child) {
    margin-right: 6px;
  }
  ${({ theme, isActive }) => theme.cardProps?.priceItemStyles?.(isActive)}
`;

const PriceAmount = styled.div`
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  font-size: 16px;
  color: ${({ theme }) => theme.palette.common.white};
`;

const PriceLabel = styled.div`
  font-weight: ${({ theme }) => theme.typography.fontWeights.extraBold};
  font-size: ${({ theme }) => theme.typography.fontSizes.mediumSmall}px;
  color: ${({ theme }) => theme.palette.primary.accentBorder};
`;

const CardTopContent = styled.div``;

const CardContentInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.cardProps.contentPadding || 0};
  ${({ theme }) => theme.cardProps?.cardContentInnerStyles}
`;

const CardInner = styled.div<{ hasImage: boolean }>`
  padding: ${({ theme }) => theme.cardProps.padding};
  ${({ theme, hasImage }) => theme.cardLayout(hasImage)}
`;

export {
  Card,
  CardImage,
  CardContent,
  CardDescription,
  CardHeader,
  PriceAmount,
  PriceContainer,
  PriceItem,
  PriceLabel,
  CardTopContent,
  CardInner,
  CardContentInner,
};
