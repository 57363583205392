import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { CheckoutFormContainer, CheckoutFormForm } from "./styles";
import GenericInput from "../../atoms/GenericInput";
import { useTranslation } from "react-i18next";
import GenericButton from "../../atoms/GenericButton";
import { useSelector } from "react-redux";
import { jsonResponseInterface } from "../../../interfaces/json-response.interface";
import { aggregateOrderData } from "../../../utils/aggregateOrderData";
import { cartItem } from "../../../store/slices/menu.slice";

export type personalDetailsType = {
  name: "";
  city: "";
  street: "";
  building: "";
  phone: "";
};

const CheckoutForm = () => {
  const { t } = useTranslation();

  const response = useSelector(
    (state: { menuSlice: { response: jsonResponseInterface } }) =>
      state.menuSlice?.response
  );
  const cart = useSelector(
    (state: { menuSlice: { cart: cartItem[] } }) => state.menuSlice.cart
  );

  const [formData, setFormData] = useState<personalDetailsType>({
    name: "",
    city: "",
    street: "",
    building: "",
    phone: "",
  });

  useEffect(() => {
    const _defaultData = window.localStorage.getItem("personalDetails");
    if (!_defaultData) return;
    const defaultData: personalDetailsType = JSON.parse(_defaultData);
    setFormData(() => defaultData);
  }, []);

  const submitForm = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    window.localStorage.removeItem("personalDetails");
    window.localStorage.setItem("personalDetails", JSON.stringify(formData));
    if (!response) return; //this will have to throw an error

    const cartContent = aggregateOrderData({
      cart,
      menu: response,
      personalDetails: formData,
    });
    //this will have to be replaced with a proper api call to the order api
    alert(`You ordered ${JSON.stringify(cartContent)}`);
  };

  const getInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((formData) => {
      return {
        ...formData,
        [e.target.id]: e.target.value,
      };
    });
  };

  const { name, city, street, building, phone } = formData;

  return (
    <CheckoutFormContainer>
      <CheckoutFormForm onSubmit={submitForm}>
        <GenericInput
          type="text"
          placeholder={t("Checkout:Your name")}
          variant="minimal"
          id="name"
          required
          defaultValue={name}
          onChange={getInputValue}
        />
        <GenericInput
          type="text"
          placeholder={t("Checkout:City/town")}
          variant="minimal"
          id="city"
          required
          defaultValue={city}
          onChange={getInputValue}
        />
        <GenericInput
          type="text"
          placeholder={t("Checkout:Street")}
          variant="minimal"
          id="street"
          required
          defaultValue={street}
          onChange={getInputValue}
        />
        <GenericInput
          type="text"
          placeholder={t("Checkout:Building")}
          id="building"
          variant="minimal"
          required
          defaultValue={building}
          onChange={getInputValue}
        />
        <GenericInput
          type="text"
          placeholder={t("Checkout:Phone")}
          id="phone"
          variant="minimal"
          required
          defaultValue={phone}
          onChange={getInputValue}
        />
        <GenericButton color="primary">
          {t("Checkout:confirm my order")}
        </GenericButton>
      </CheckoutFormForm>
    </CheckoutFormContainer>
  );
};

export default CheckoutForm;
