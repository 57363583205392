import { combineReducers } from "@reduxjs/toolkit";
import menuSlice from "./menu.slice";
import uiSlice from "./ui.slice";
import userSlice from "./user.slice";

const rootReducer = combineReducers({
  menuSlice,
  userSlice,
  uiSlice,
});

export default rootReducer;
