import styled from "styled-components";

const CheckoutContainer = styled.div`
  margin-top: 20px;
`;

const BackButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transform: rotate(-90deg);
    width: 20px;
    height: 20px;
    margin-right: 10px;
    path {
      fill: white;
    }
  }
`;

const CheckoutContainerInner = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 10px;
  margin-top: 20px;
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export { CheckoutContainer, BackButton, CheckoutContainerInner };
