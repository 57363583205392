import React, { ComponentType } from "react";
import { AppContainerInner } from "../styles";
import Header from "../../molecules/Header";
import { useFetchMenuOrCache } from "../../../utils/hooks/useFetchMenuOrCache";

const MinimalLayout: ComponentType = ({ children }) => {
  const { name, logo } = useFetchMenuOrCache();

  return (
    <>
      <Header name={name} logo={logo} />

      <AppContainerInner>{children}</AppContainerInner>
    </>
  );
};

export default MinimalLayout;
