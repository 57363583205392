import styled, { css } from "styled-components";

const centerContentStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  ${centerContentStyles};
`;

const DialogHolder = styled.div<{ centerContent?: boolean }>`
  min-width: 300px;
  min-height: 200px;
  padding: 30px;
  background-color: ${({ theme }) => theme.palette.primary.elevation};
  box-shadow: ${({ theme }) => theme.shadow.large};
  border-radius: ${({ theme }) => theme.borders.generalRadius};
  ${({ centerContent }) => (centerContent ? centerContentStyles : "")}
  position: relative;
`;

const DialogContent = styled.div``;

const DialogTitle = styled.h2``;

const DialogCloseButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

export {
  BackDrop,
  DialogContent,
  DialogHolder,
  DialogTitle,
  DialogCloseButton,
};
