import React from "react";
import { menuCategoryInterface } from "../interfaces/menu-category.interface";
import { hasItemsInTree } from "./hasItemsInTree";
import CategoryItem from "../components/molecules/CategoryItem";

export const categoryPointersRenderer = (
  categories: menuCategoryInterface[],
  expanded?: boolean
) => {
  return categories.map((category, i) => {
    if (!hasItemsInTree(category)) return null;
    return (
      <CategoryItem
        label={category.name}
        pointer={`category-${category.id}`}
        key={`category-chip-${i}`}
        menus={category.menus}
        nestLevel={category.nestLevel}
        expanded={expanded}
      />
    );
  });
};
