import { css } from "styled-components";

export const defaultTheme = {
  typography: {
    fontFamily: "Montserrat",
    headingsFontFamily: "Montserrat",
    headingsFontFamilyType: "sans-serif",
    fontSizes: {
      small: 12,
      mediumSmall: 14,
      medium: 16,
      extraMedium: 19,
      large: 24,
      extraLarge: "3em",
    },
    fontWeights: {
      lighter: 400,
      bold: 600,
      medium: 500,
      extraBold: 700,
    },
  },
  shadow: {
    main: "0px 8px 20px rgba(0, 0, 0, 0.25)",
    mainUp: "0px -8px 20px rgba(0, 0, 0, 0.25)",
    large: "0px 10px 30px rgba(0, 0, 0, 0.3)",
  },
  palette: {
    common: {
      white: "#fff",
      black: "#000",
    },
    primary: {
      main: "#1b1b1b",
      headerFooter: "#1b1b1b",
      highlightIconFill: "#1b1b1b",
      accent: "linear-gradient(301.07deg, #F85032 7.18%, #E73827 96.48%)",
      accentBorder: "#F85032",
      elevation: "#202020",
      elevation1: "#303030",
    },
    custom: {
      successGreen: "#4cd964",
      dangerRed: "#d0021b",
      warningYellow: "#ffcc00",
    },
  },
  borders: {
    generalRadius: "10px",
    small: "5px",
    medium: "8px",
    extraMedium: "6px",
    topSheets: "0 0 10px 10px",
    bottomSheets: "10px 10px 0 0",
  },
  container: css`
    width: 90%;
    max-width: 1300px;
    margin: auto;
  `,
  cardProps: {
    padding: "4px",
    border: (isActive: boolean) => css`
      border: ${({ theme }) =>
        isActive
          ? `2px solid ${theme.palette.primary.accentBorder}`
          : "2px solid transparent"};
      border-top-color: ${({ theme }) => theme.palette.primary.accentBorder};
    `,
    inactiveStyles: css`
      filter: grayscale(1);
      opacity: 0.5;
    `,
  },
  cardLayout: (hasImage: boolean) => css`
    display: block;
  `,
};
