import styled, { keyframes } from "styled-components";

const slideOut = keyframes`
  0%{transform: scaleY(0)}
  100%{transform: scaleY(1)}
`;

const AboutContainerOuter = styled.div`
  position: sticky;
  bottom: 55px;
  width: 100%;
  background: ${({ theme }) => theme.palette.primary.headerFooter};
  border-top: 2px solid ${({ theme }) => theme.palette.primary.accentBorder};
  border-radius: ${({ theme }) => theme.borders.bottomSheets};
  box-shadow: ${({ theme }) => theme.shadow.mainUp};
  z-index: 97;
  user-select: none;
  min-height: 15vh;
  max-height: 60vh;
  transform-origin: bottom center;
  animation: ${slideOut} 0.3s ease-in-out;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    @media screen and (max-width: 768px) {
      width: 0;
    }
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.primary.accent};
    border-radius: ${({ theme }) => theme.borders.generalRadius};
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.primary.elevation1};
    border-radius: ${({ theme }) => theme.borders.generalRadius};
  }
`;

const AboutContainerInner = styled.div`
  ${({ theme }) => theme.container};
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 20px;
  padding-bottom: 30px;
`;
const AboutHeader = styled.div`
  font-weight: ${({ theme }) => theme.typography.fontWeights.extraBold};
  color: ${({ theme }) => theme.palette.primary.accentBorder};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const AboutHeaderText = styled.div``;

const AboutDescription = styled.p`
  margin-bottom: 50px;
  font-size: ${({ theme }) => theme.typography.fontSizes.extraMedium}px;
`;

const AboutAddress = styled.p`
  margin-bottom: 10px;
`;

const AboutPhone = styled.a`
  font-weight: ${({ theme }) => theme.typography.fontWeights.extraBold};
  color: ${({ theme }) => theme.palette.primary.accentBorder};
`;

const AboutName = styled.h2`
  margin: 10px 0 3px;
  color: ${({ theme }) => theme.palette.common.white};
`;

const AboutContactsHolder = styled.div``;

const AboutType = styled.div`
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.extraBold};
  color: ${({ theme }) => theme.palette.primary.accentBorder};
`;

export {
  AboutContainerInner,
  AboutContainerOuter,
  AboutHeader,
  AboutAddress,
  AboutDescription,
  AboutPhone,
  AboutName,
  AboutContactsHolder,
  AboutType,
  AboutHeaderText,
};
