import React from "react";
import { menuCategoryInterface } from "../interfaces/menu-category.interface";
import MenuCategory from "../components/organisms/MenuCategory";
import { hasItemsInTree } from "./hasItemsInTree";

export const menuRenderer = (categories: menuCategoryInterface[]) => {
  return categories.map((category, i) => {
    if (!hasItemsInTree(category)) return null;
    return (
      <MenuCategory
        {...category}
        key={`category-${category.id}`}
        id={`category-${category.id}`}
      />
    );
  });
};
