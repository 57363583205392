import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { TipsHolder, TipsLegal, TipsLink, TipsTOC, TitleText } from "./styles";
import { createRichAlert } from "../../../utils/createRichAlert";
import { useDeviceId } from "../../../utils/hooks/selectors/useDeviceId";
import { goUp } from "../../../utils/goUp";
import { useMenuInteractive } from "../../../store/slices/menu.slice";
import Chip from "../../atoms/Chip";
import { trackAnalyticsEvent } from "../../../utils/trackAnalyticsEvent";

const TipsSection = () => {
  const { t } = useTranslation();

  const {
    params: { id },
  }: { params: { id: string } } = useRouteMatch();

  const deviceId = useDeviceId();

  const initTip = async (amount: number) => {
    goUp();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/payment/tips/${id}?amount=${amount}&lang=${navigator.languages[0]}&type=card`,
        {
          method: "GET",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-Device-Id": deviceId,
          },
        }
      );

      const json = await res.json();

      localStorage.setItem("txid", json.txid);
      trackAnalyticsEvent("tip-initialized", { event_label: json.txid });
      window.open(json.url, "_top");
    } catch (e) {
      createRichAlert({
        type: "error",
        message: t("Tips:Something went wrong, sorry..."),
      });
    }
  };

  const { tips } = useMenuInteractive();
  const renderTipButtons = useMemo(() => {
    return tips?.amounts
      ? Object.values(tips.amounts).map((item) => (
          <Chip onClick={initTip} selectable value={item}>
            {item}
          </Chip>
        ))
      : null;
    //eslint-disable-next-line
  }, [tips]);

  // const TipsFallback = () => (
  //   <TitleText>{t("Tips:The restaurant has not enabled tips")}</TitleText>
  // );
  //
  // if (!tips || !tips.allow) return <TipsFallback />;

  if (!tips || !tips.allow) return null;

  return (
    <div>
      <TitleText>{t("Tips:Tip the waiter")}</TitleText>
      <TipsHolder>{renderTipButtons}</TipsHolder>
      <TipsTOC>
        {t(`Tips:By initiating the payment, you agree to the `)}
        <TipsLink
          onClick={() =>
            window.open(
              "https://tascombank.ua/files/Pravyla_pryimannia_i_obrobky_zapytiv_korystuvachiv_dlia_zdiisnennia_perekazu_koshtiv_v_natsionalnii_valiuti.pdf"
            )
          }
        >
          {t(`Tips:Terms`)}
        </TipsLink>
      </TipsTOC>
      <TipsLegal>
        {t(
          "About:QR menu service is provided by IE Popel D.O., Tax 2937411677, Ukraine, Lviv, 79000, PO Box 27, tel. +3806305707814"
        )}
      </TipsLegal>
    </div>
  );
};

export default TipsSection;
