import styled from "styled-components";

const CartItemCard = styled.div`
  position: relative;
  background: ${({ theme }) => theme.palette.primary.elevation1};
  box-shadow: ${({ theme }) => theme.shadow.main};
  border-radius: ${({ theme }) =>
    theme.cartItemProps?.borderRadius || theme.borders.generalRadius};
  border: 2px solid transparent;
  border-top-color: ${({ theme }) => theme.palette.primary.accentBorder};
  width: 100%;
  padding: 10px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const CartItemName = styled.h3``;

const PriceSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  justify-items: center;
`;

const PriceButton = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary.accent};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    width: 15px;
    height: 15px;
    pointer-events: none;
    path {
      fill: ${({ theme }) => theme.palette.primary.highlightIconFill};
    }
  }
`;

const Quantity = styled.h3`
  white-space: nowrap;
  text-align: right;
`;

const CartItemInner = styled.div`
  display: grid;
  align-items: center;
  width: calc(100% - 50px);
  grid-template-columns: 65% 35%;
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const PriceSectionOuter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  align-items: center;
  @media screen and (max-width: 500px) {
    margin-top: 10px;
  }
`;

export {
  CartItemCard,
  CartItemName,
  PriceButton,
  PriceSection,
  Quantity,
  CartItemInner,
  PriceSectionOuter,
};
