import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  deviceId: localStorage.getItem("deviceId"),
};

export const userSlice = createSlice({
  name: "user-slice",
  initialState,
  reducers: {
    setdeviceId: (state) => {
      const __tempId = uuidv4();
      const existingId = localStorage.getItem("deviceId");
      if (!existingId) localStorage.setItem("deviceId", __tempId);
      state.deviceId = existingId || __tempId;
    },
  },
});

export const { setdeviceId } = userSlice.actions;

export default userSlice.reducer;
