import { useEffect } from "react";
import { useDeviceId } from "./selectors/useDeviceId";
import { trackAnalyticsEvent } from "../trackAnalyticsEvent";

export const useTxidPing = () => {
  const txid = localStorage.getItem("txid");
  const deviceId = useDeviceId();

  useEffect(() => {
    if (!txid) return;
    const interval = setInterval(async () => {
      try {
        await fetch(
          `${process.env.REACT_APP_API_URL}/payment/status?txid=${txid}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "X-Device-Id": deviceId,
            },
          }
        );
        trackAnalyticsEvent("tip-finalized", { event_label: txid });
        localStorage.removeItem("txid");
        clearInterval(interval);
      } catch (e) {
        clearInterval(interval);
        localStorage.removeItem("txid");
      }
    }, 3000);

    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, [txid]);
};
