import React, { ChangeEvent, useRef } from "react";
import { SearchContainerOuter, SearchContainerInner } from "./styles";
import { useDispatch } from "react-redux";
import {
  clearSearchResults,
  searchInMenu,
} from "../../../store/slices/menu.slice";
import GenericInput from "../../atoms/GenericInput";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";
import GenericButton from "../../atoms/GenericButton";
import CrossIcon from "../../../assets/icons/cross.svg";
import { trackAnalyticsEvent } from "../../../utils/trackAnalyticsEvent";
import { useUi } from "../../../utils/hooks/useUi";
import { useMenuSlice } from "../../../utils/hooks/useMenu";
import { toggleSearch } from "../../../store/slices/ui.slice";
import { useAutoOpenPopup } from "../../../utils/hooks/useAutoOpenPopup";

const SearchBar = () => {
  const { isSearchOpen } = useUi();
  const { searchKey: defaultSearchKey } = useMenuSlice();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const openPopup = () => {
    dispatch(clearSearchResults());
    !isSearchOpen && trackAnalyticsEvent("popup-search");
    dispatch(toggleSearch());
  };

  useAutoOpenPopup("#search", openPopup);

  const debounceTracking = useRef(
    debounce((searchKey: string) => {
      trackAnalyticsEvent("search", { event_label: searchKey });
    }, 2000)
  ).current;

  const debounceDispatch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const searchKey = e.target.value;
    dispatch(searchInMenu(searchKey));

    if (searchKey && searchKey.length > 1) {
      debounceTracking(searchKey);
    }
  }, 400);

  // const items = useMemo(() => document.querySelectorAll(".card"), []);
  // const debounceDispatch = debounce((e: ChangeEvent<HTMLInputElement>) => {
  //   e.persist();
  //   const searchKey = e.target.value;
  //
  //   instance.unmark();
  //   instance.mark(searchKey);
  //
  //   items.forEach((it) => {
  //     if (it) {
  //       const text = it.textContent?.trim().toLowerCase();
  //       if (text?.includes(searchKey.trim().toLowerCase())) {
  //         // it.parentElement?.classList.remove("hide");
  //         // it.parentElement?.classList.add("show");
  //
  //         it.parentElement!.style.display = "block";
  //       } else {
  //         // it.parentElement?.classList.remove("show");
  //         // it.parentElement?.classList.add("hide");
  //
  //         it.parentElement!.style.display = "none";
  //       }
  //     }
  //   });
  //   dispatch(searchInMenu(searchKey));
  //   if (searchKey && searchKey.length > 1) {
  //     debounceTracking(searchKey);
  //   }
  // }, 100);

  if (isSearchOpen)
    return (
      <SearchContainerOuter>
        <SearchContainerInner>
          <GenericInput
            type="text"
            autoFocus
            placeholder={t("SearchBar:Category, dish, drink, ingredient...")}
            onChange={(e) => {
              debounceDispatch(e);
            }}
            defaultValue={defaultSearchKey || ""}
          />
          <GenericButton
            onClick={() => {
              dispatch(clearSearchResults());
            }}
            square
            color="secondaryWithBackground"
            margins={[0, 0, 0, 10]}
          >
            <img src={CrossIcon} alt={t("SearchBar:Close")} />
          </GenericButton>
        </SearchContainerInner>
      </SearchContainerOuter>
    );
  else return null;
};

export default SearchBar;
