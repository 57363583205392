export const defaultArray = [
  "call",
  "bill",
  "payWithCash",
  "payWithCard",
] as const;

export interface callOptionsInterface {
  default: typeof defaultArray;
  custom: string[];
}
