import { useEffect, useCallback, useState, useMemo } from "react";

export const useWindowSize = (dimension: "height" | "width" = "width") => {
  const [windowSize, setWindowSize] = useState(
    dimension === "width" ? window.innerWidth : window.innerHeight
  );
  const resizeFunction = useCallback(
    (event) => {
      if (event) {
        setWindowSize(window.innerWidth);
      }
    },
    [setWindowSize]
  );

  useEffect(() => {
    window.addEventListener("resize", resizeFunction, false);
    return () => {
      window.removeEventListener("resize", resizeFunction, false);
    };
  }, [resizeFunction]);

  return useMemo(() => windowSize, [windowSize]);
};
