import React, { FC } from "react";
import { GenericEmptyStateInterface } from "./interface";
import {
  EmptyStateActionsContainer,
  EmptyStateHolder,
  EmptyStateText,
  EmptyStateTitle,
} from "./styles";

const GenericEmptyState: FC<GenericEmptyStateInterface> = ({
  actions,
  text,
  title,
  image,
}) => {
  return (
    <EmptyStateHolder>
      {image && <img src={image} alt={title} />}
      <EmptyStateTitle>{title}</EmptyStateTitle>
      <EmptyStateText>{text}</EmptyStateText>
      <EmptyStateActionsContainer>{actions}</EmptyStateActionsContainer>
    </EmptyStateHolder>
  );
};

export default GenericEmptyState;
