import React, { FC } from "react";
import { CornerLabelInterface } from "./interface";
import { CornerLabelContainer } from "./styles";

const CornerLabel: FC<CornerLabelInterface> = ({
  isActive,
  position = "topLeft",
  children,
  onClick,
}) => {
  return (
    <CornerLabelContainer
      isActive={isActive}
      className={position}
      onClick={onClick}
    >
      {children}
    </CornerLabelContainer>
  );
};

export default CornerLabel;
