import React, { FC } from "react";
import { CartItemInterface } from "./interface";
import {
  CartItemCard,
  CartItemInner,
  CartItemName,
  Quantity,
  PriceButton,
  PriceSection,
  PriceSectionOuter,
} from "./styles";
import CornerLabel from "../../atoms/CornerLabel";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";
import { useDispatch, useSelector } from "react-redux";
import { changeQuantity, toggleInCart } from "../../../store/slices/menu.slice";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plusIcon.svg";
import { ReactComponent as MinusIcon } from "../../../assets/icons/minusIcon.svg";
import ReactMarkdown from "react-markdown";

const CartItem: FC<CartItemInterface> = ({
  id: menuItemId,
  label,
  amount,
  quantity,
  priceId,
  itemName,
}) => {
  const currency = useSelector(
    (state: { menuSlice: { response: { meta: { currency: string } } } }) =>
      state.menuSlice.response?.meta?.currency
  );

  const dispatch = useDispatch();

  const onMinusClick = () =>
    dispatch(
      changeQuantity({ id: menuItemId.toString(), type: "decrement", priceId })
    );

  const onPlusClick = () =>
    dispatch(
      changeQuantity({ id: menuItemId.toString(), type: "increment", priceId })
    );

  return (
    <CartItemCard>
      <CornerLabel
        isActive={true}
        position="topRight"
        onClick={() => dispatch(toggleInCart({ id: menuItemId, priceId }))}
      >
        <CrossIcon />
      </CornerLabel>
      <CartItemInner>
        <CartItemName>
          <ReactMarkdown>
            {`${itemName} ${label && `(${label})`}`}
          </ReactMarkdown>
        </CartItemName>

        <PriceSectionOuter>
          <PriceSection>
            <PriceButton onClick={onMinusClick}>
              <MinusIcon />
            </PriceButton>
            <Quantity>{quantity || 1}</Quantity>
            <PriceButton onClick={onPlusClick}>
              <PlusIcon />
            </PriceButton>
          </PriceSection>

          <Quantity>
            {((amount ?? 0) * (quantity || 1)).toFixed(2)} {currency}
          </Quantity>
        </PriceSectionOuter>
      </CartItemInner>
    </CartItemCard>
  );
};

export default CartItem;
