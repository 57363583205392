import styled, { css } from "styled-components";
import decorationPattern from "../assets/Linth.svg";
import { getRandomBackgroundPosition } from "../utils/getRandomBackgroundPosition";

export const minimalistTheme = {
  typography: {
    fontFamily: "Montserrat",
    headingsFontFamily: "Playfair Display",
    headingsFontFamilyType: "serif",
    fontSizes: {
      small: 12,
      mediumSmall: 14,
      medium: 16,
      extraMedium: 19,
      large: 24,
      extraLarge: "3em",
    },
    fontWeights: {
      lighter: 400,
      bold: 600,
      medium: 500,
      extraBold: 700,
    },
  },
  shadow: {
    main: "none",
    mainUp: "none",
    large: "none",
  },
  palette: {
    common: {
      white: "#000",
      black: "#fff",
    },
    // primary: {
    //   main: "#fff",
    //   headerFooter: "#fff",
    //   highlightIconFill: "#fff",
    //   accent: "#574141",
    //   accentBorder: "#574141",
    //   elevation: "#7D5A5A",
    //   elevation1: "#F1D1D1",
    // },
    primary: {
      main: "#fff",
      headerFooter: "#fff",
      highlightIconFill: "#fff",
      accent: "#2F4858",
      accentBorder: "#2F4858",
      elevation: "#7D5A5A",
      elevation1: "#F1D1D1",
    },
    custom: {
      successGreen: "#4cd964",
      dangerRed: "#d0021b",
      warningYellow: "#ffcc00",
    },
  },
  borders: {
    generalRadius: "0 40px 40px 40px",
    small: "0 10px 10px 10px",
    medium: "0 10px 10px 10px",
    extraMedium: "0 40px 40px 40px",
    topSheets: "0 0 10px 10px",
    bottomSheets: "10px 10px 0 0",
  },
  container: css`
    width: 90%;
    max-width: 1300px;
    margin: auto;
  `,
  cardLayout: (hasImage: boolean) => css`
    display: grid;
    grid-template-columns: ${hasImage ? "1fr 1.5fr" : "1fr"};
    gap: 10px;
  `,
  cardProps: {
    padding: "0",
    contentPadding: "5px",
    fontColor: "#fff",
    border: (isActive: boolean) => css`
      border: none;
      border-radius: 0 0 0 40px;
    `,
    inactiveStyles: css`
      filter: grayscale(1);
      opacity: 0.5;
    `,
  },
  cornerLabelStyles: css`
    padding: 7px 10px;
    &.topLeft {
      top: 0;
      left: 0;
      //TODO: these have to be changed, not used anywhere now
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
    }
    &.topRight {
      top: 0;
      right: 0;
      border-radius: 10px 0 10px 10px;
    }
    &.bottomRight {
      bottom: 0;
      right: 0;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &.bottomLeft {
      bottom: 0;
      left: 0;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  `,
  cartItemProps: {
    borderRadius: "0 0 0 10px",
  },
  desktopColumnsWidth: 400,
  categoryHeaderDecoration: styled.div`
    width: 100px;
    height: 100px;
    background: url(${decorationPattern});
    background-position: ${getRandomBackgroundPosition()};
    border-radius: 0 0 0 40px;
  `,
  generalContainerOverride: css`
    @media screen and (max-width: 600px) {
      width: 100%;
      padding-left: 20px;
    }
  `,
};
