import styled from "styled-components";

const EmptyStateHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 80px;
`;

const EmptyStateTitle = styled.h1``;

const EmptyStateText = styled.p``;

const EmptyStateActionsContainer = styled.div``;

export {
  EmptyStateHolder,
  EmptyStateTitle,
  EmptyStateText,
  EmptyStateActionsContainer,
};
