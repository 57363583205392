import { cartItem } from "../store/slices/menu.slice";
import { personalDetailsType } from "../components/organisms/CheckoutForm";
import { menuItemInterface } from "../interfaces/menu-item.interface";
import { findDeepNested } from "./findDeepNested";
import { jsonResponseInterface } from "../interfaces/json-response.interface";

type aggregateOrderDataArgs = {
  cart: cartItem[];
  menu: jsonResponseInterface;
  personalDetails?: personalDetailsType;
};

interface richMenuItemInterface extends menuItemInterface {
  amount: number;
  quantity: number;
}

type aggregateOrderDataReturn = {
  items: richMenuItemInterface[];
  personalDetails: personalDetailsType;
};

export const aggregateOrderData = (
  args: aggregateOrderDataArgs
): aggregateOrderDataReturn => {
  const { cart, menu, personalDetails } = args;

  const itemsInCart = cart.map((item) => {
    const _item: menuItemInterface = findDeepNested(
      menu,
      "id",
      parseInt(item.id.split("-")[0])
    );

    return {
      ..._item,
      amount: item.amount,
      quantity: item.quantity ?? 1,
    } as richMenuItemInterface;
  });

  return {
    items: [...itemsInCart],
    personalDetails: personalDetails!,
  };
};

export const aggregateFlatOrderData = (args: aggregateOrderDataArgs) => {
  const { cart, menu } = args;

  return cart.map((item) => {
    const _item: menuItemInterface = findDeepNested(
      menu,
      "id",
      parseInt(item.id.split("-")[0])
    );

    const getLabelFromPrice = () => {
      return _item?.prices.find((p) => parseFloat(p.amount) === item.amount)
        ?.label;
    };
    //title, price, label, quantity
    return [_item.title, item.amount, getLabelFromPrice(), item.quantity ?? 1];
  });
};
