import React, { FC, useEffect, useState } from "react";
import {
  CartSummaryContainer,
  CartSummaryHeader,
  CartSummaryTotal,
  SmallText,
} from "./styles";
import GenericButton from "../../atoms/GenericButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { cartItem, clearCart } from "../../../store/slices/menu.slice";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useIsInOrderContext } from "../../../utils/hooks/useIsInOrderContext";
import { menuInteractiveInterface } from "../../../interfaces/menu-interactive.interface";
import { jsonResponseInterface } from "../../../interfaces/json-response.interface";
import { aggregateFlatOrderData } from "../../../utils/aggregateOrderData";
import { createRichAlert } from "../../../utils/createRichAlert";
import { toggleCart } from "../../../store/slices/ui.slice";
import { trackAnalyticsEvent } from "../../../utils/trackAnalyticsEvent";

type CartSummaryProps = { hideDefaultAction?: boolean };

const CartSummary: FC<CartSummaryProps> = ({ hideDefaultAction }) => {
  const { t } = useTranslation();
  const [total, setTotal] = useState<number | string>(0);

  const cartItems = useSelector(
    (state: { menuSlice: { cart: cartItem[] } }) => state.menuSlice.cart
  );

  const response = useSelector(
    (state: { menuSlice: { response: jsonResponseInterface } }) =>
      state.menuSlice?.response
  );

  const history = useHistory();

  const {
    params: { id },
  }: { params: { id: string } } = useRouteMatch();

  const currency = useSelector(
    (state: { menuSlice: { response: { meta: { currency: string } } } }) =>
      state.menuSlice.response?.meta?.currency
  );

  useEffect(() => {
    if (!cartItems.length) return;
    let _total = cartItems
      .reduce((acc, cur) => {
        return acc + cur.amount! * (cur.quantity || 1);
      }, 0)
      .toFixed(2);

    setTotal(() => _total);
  }, [cartItems]);

  const isInOrderContext = useIsInOrderContext();

  const interactive = useSelector(
    (state: {
      menuSlice: { response: { interactive: menuInteractiveInterface } };
    }) => state.menuSlice.response?.interactive
  );

  const areOrdersEnabled = interactive?.tableService?.allowTableOrders;

  const isInteractive = !!interactive && !Array.isArray(interactive);

  const deviceId = useSelector(
    (state: { userSlice: { deviceId: string } }) => state.userSlice.deviceId
  );

  const dispatch = useDispatch();

  const onOrderClick = async () => {
    const cartContent = aggregateFlatOrderData({
      menu: response,
      cart: cartItems,
    });

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/restaurants/${id}/waiterCall`,
        {
          body: JSON.stringify({
            type: "order",
            message: cartContent,
          }),
          method: "POST",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-Device-Id": deviceId,
          },
        }
      );
      if (res.status === 201) {
        createRichAlert({
          type: "success",
          message: t("WaiterPopup:Your order has been received"),
          header: t("WaiterPopup:Thanks!"),
        });
        trackAnalyticsEvent("tableOrder-used", {
          event_label: total.toString(),
        });
        dispatch(clearCart());
        dispatch(toggleCart());
      } else if (res.status === 403) {
        throw new Error(
          t("WaiterPopup:Please rescan the QR code on the table and try again.")
        );
      } else if (res.status === 422) {
        throw new Error(
          t("WaiterPopup:You have made too many calls, please wait a bit.")
        );
      }
    } catch (e: any) {
      createRichAlert({ type: "error", message: e.message });
    }
  };
  return (
    <CartSummaryContainer>
      <CartSummaryHeader>{t("Cart:Total")}</CartSummaryHeader>
      <CartSummaryTotal>
        {total} {currency}
      </CartSummaryTotal>
      {hideDefaultAction || !isInOrderContext ? null : (
        <GenericButton
          color="primary"
          onClick={() => history.push(`/order/${id}/checkout`)}
        >
          {t("Cart:order")}
        </GenericButton>
      )}

      {areOrdersEnabled ? (
        <GenericButton color="primary" onClick={onOrderClick}>
          {t("Cart:order")}
        </GenericButton>
      ) : isInteractive ? (
        <SmallText>
          {t("Cart:Restaurant has not enabled ordering from the table")}
        </SmallText>
      ) : null}
    </CartSummaryContainer>
  );
};

export default CartSummary;
