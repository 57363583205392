import styled, { keyframes } from "styled-components";
import { AlertInterface } from "./interface";

const slideOut = keyframes`
  0%{transform: translateX(1000px)}
  100%{transform: translateX(0)}
`;

const AlertContainer = styled.div<{ type: AlertInterface["type"] }>`
  position: fixed;
  right: 20px;
  top: 20px;
  background: var(--bg-style);
  color: var(--font-color);
  z-index: ${Number.MAX_SAFE_INTEGER};
  display: flex;
  padding: 10px 15px;
  border-radius: 5px;
  animation: ${slideOut} 0.3s ease-in-out;
  transform-origin: right center;
  border: 1.5px solid var(--font-color);
  border-left: 4px solid;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
  border-left-color: ${({ type }) => {
    switch (type) {
      case "success":
        return "#4cd964";
      case "error":
        return "#d0021b";
      case "warning":
        return "#ffcc00";
      default:
        return "lightBlue";
    }
  }};
`;

const AlertInner = styled.div`
  margin-left: 10px;
`;

const AlertHeader = styled.h4`
  text-transform: capitalize;
`;

const AlertText = styled.p``;

export { AlertContainer, AlertText, AlertHeader, AlertInner };
