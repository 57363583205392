import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jsonResponseInterface } from "../../interfaces/json-response.interface";
import { menuCategoryInterface } from "../../interfaces/menu-category.interface";
import { menuItemInterface } from "../../interfaces/menu-item.interface";
import cloneDeep from "lodash/cloneDeep";
import { goUp } from "../../utils/goUp";
// import { cacheResponse } from "../../utils/cashing/cacheResponse";
// import { validateCache } from "../../utils/cashing/validateCache";
import { cacheCart, clearCartCache } from "../../utils/cashing/casheCart";
import { useSelector } from "react-redux";
import { menuInteractiveInterface } from "../../interfaces/menu-interactive.interface";

const GET_MENU = "menu/get";

export type cartItem = {
  id: string;
  priceId?: number;
  quantity?: number;
  amount?: number;
  label?: string;
  itemName?: string;
};

export interface IMenuSlice {
  id: string;
  loading: boolean;
  response: null | jsonResponseInterface;
  searchResults: null | menuCategoryInterface[];
  cart: cartItem[];
  error: any;
  searchKey: null | string;
}

export const initialState: IMenuSlice = {
  id: "",
  loading: true,
  response: null,
  searchResults: null,
  cart: [],
  error: null,
  searchKey: null,
};

export const getMenu = createAsyncThunk(
  GET_MENU,
  async (id: string, { rejectWithValue, getState }) => {
    // const exists = validateCache(id);
    //
    // if (exists) {
    //   const response = cacheResponse(id);
    //   return { response, id };
    // } else {

    //@ts-ignore
    const { treeView } = getState().uiSlice;

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/menu/${id}${
          treeView ? "?tree=1" : ""
        }`,
        {
          credentials: "include",
          mode: "cors",
        }
      );
      const response = await res.json();
      return { response, id };
    } catch (e) {
      //redux toolkit requires to explicitly return a reject
      return rejectWithValue(e);
    }

    // }
  }
);

//welcome to the madness
export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    persistSearchKey: (state, { payload: searchKey }) => {
      state.searchKey = searchKey;
    },
    searchInMenu: (state, { payload: searchKey }) => {
      if (state.response?.menu) {
        //run this only if the menu has been fetched correctly
        //this makes the reducer immutable. We need a deep clone as the items are nested.
        const _initial = cloneDeep(state.response.menu);
        //set the search key into state to later prefill the searchbar with a default value

        state.searchKey = searchKey;

        const searchInCategory = (category: menuCategoryInterface) => {
          //recursive part of the search algo, allows us to search in sub categories
          if (category.menus)
            category.menus.map((cat) => searchInCategory(cat));
          //we need to return the whole category if the category name corresponds to the search key provided
          if (category.name.toLowerCase().includes(searchKey.toLowerCase())) {
            return category;
          } else {
            category.items = category.items.filter(
              (item: menuItemInterface) => {
                //a clever trick to search on all the field values:
                return Object.values(item).some((v) => {
                  //check if the value is string, as we are matching it to the string search key. Casting the field value to string will not work as might get [object Object]
                  //this will not search in second level nests - like prices array in our case. But this is not a requirement anyway. Probably could JSON.stringify() for this to work.
                  if (typeof v === "string") {
                    return v.toLowerCase().includes(searchKey.toLowerCase());
                    //not a match - filter it out
                  } else return false;
                });
              }
            );
          }
          //scroll up the page. This is to prevent the page freezing in an weird spot
          goUp();
          //if the category has no matched items in it - nullify the category
          return category.items.length || category.menus?.length
            ? category
            : null;
        };
        // @ts-ignore
        state.searchResults = _initial
          .map((category: menuCategoryInterface) => {
            return searchInCategory(category);
          })
          .filter((res) => res !== null);
      }
    },
    clearSearchResults: (state) => {
      state.searchResults = null;
      state.searchKey = null;
      // const items = document.querySelectorAll(".card");
      // items.forEach((it) => {
      //   if (it) {
      //     it.classList.remove("hide");
      //     it.classList.add("show");
      //   }
      // });
    },

    toggleInCart: (state, { payload: cartItem }: { payload: cartItem }) => {
      const { id, priceId } = cartItem;
      //check if the item is already in cart

      if (
        state.cart.find((item) => item.id === id && item.priceId === priceId)
      ) {
        //if the item is already in cart - remove it
        state.cart = state.cart.filter(
          (item) => !(item.id === id && item.priceId === priceId)
        );
      } else {
        //if the item is not in cart - add it
        state.cart = [...state.cart, cartItem];
      }

      //remove from localstorage old - add to localstorage updated.
      clearCartCache(`cart-${state.id}`);
      cacheCart(`cart-${state.id}`, [...state.cart]);
    },
    changeQuantity: (state, { payload }) => {
      //use the immer way to update items. Create a proxy - mutate - update
      let toUpdate = state.cart.find(
        (item) => item.id === payload.id && item.priceId === payload.priceId
      );
      switch (payload.type) {
        case "increment":
          //if there is no quantity - set it to 1 initially
          Object.assign(toUpdate, { quantity: (toUpdate?.quantity || 1) + 1 });
          clearCartCache(`cart-${state.id}`);
          cacheCart(`cart-${state.id}`, [...state.cart]);
          return state;
        case "decrement":
          //check if there is something to decrement - if not - return early
          if (!toUpdate?.quantity || toUpdate.quantity <= 1) return state;
          else Object.assign(toUpdate, { quantity: toUpdate.quantity - 1 });
          clearCartCache(`cart-${state.id}`);
          cacheCart(`cart-${state.id}`, [...state.cart]);
          return state;
        default:
          return state;
      }
    },
    setCachedCart: (state) => {
      state.cart = cacheCart(`cart-${state.id}`);
    },
    clearCart: (state) => {
      state.cart = [];
      clearCartCache(state.id);
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getMenu.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMenu.rejected, (state, { payload }) => {
        state.loading = false;
        state.error =
          // @ts-ignore
          payload?.error?.message ||
          "Something went wrong while fetching this menu";
      })
      .addCase(getMenu.fulfilled, (state, { payload }) => {
        // @ts-ignore
        const { id, response } = payload;
        state.loading = false;
        //@ts-ignore
        state.response = response;
        state.id = id;
        // cacheResponse(id, response);
      });
  },
});

export const useMenuInteractive = () => {
  return useSelector(
    (state: {
      menuSlice: { response: { interactive: menuInteractiveInterface } };
    }) => state.menuSlice.response?.interactive
  );
};

export const {
  searchInMenu,
  clearSearchResults,
  toggleInCart,
  changeQuantity,
  setCachedCart,
  persistSearchKey,
  clearCart,
} = menuSlice.actions;

export default menuSlice.reducer;
