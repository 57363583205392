import React, { useMemo } from "react";
import {
  CategoryViewContainerInner,
  CategoryViewContainerOuter,
} from "./styles";
import { useDispatch } from "react-redux";
import { CartContainerBg } from "../Cart/style";
import { toggleCategories } from "../../../store/slices/ui.slice";
import { useMenuApiResponse, useMenuSlice } from "../../../utils/hooks/useMenu";
import { useUi } from "../../../utils/hooks/useUi";
import { categoryPointersRenderer } from "../../../utils/categoryPointersRenderer";
import { clearSearchResults } from "../../../store/slices/menu.slice";
import { trackAnalyticsEvent } from "../../../utils/trackAnalyticsEvent";
import { useAutoOpenPopup } from "../../../utils/hooks/useAutoOpenPopup";

const CategoryListView = () => {
  const { searchResults } = useMenuSlice();
  const { menu: categories } = useMenuApiResponse();
  const { isCategorySelectorOpen } = useUi();

  const openPopup = () => {
    dispatch(clearSearchResults());
    !isCategorySelectorOpen && trackAnalyticsEvent("popup-toc");
    dispatch(toggleCategories());
  };

  useAutoOpenPopup("#menu", openPopup);

  const renderCategories = useMemo(() => {
    if (searchResults && searchResults.length) {
      return categoryPointersRenderer(searchResults);
    } else if (categories && categories.length) {
      return categoryPointersRenderer(categories);
    }
  }, [categories, searchResults]);

  const dispatch = useDispatch();

  if (isCategorySelectorOpen)
    return (
      <>
        <CartContainerBg onClick={() => dispatch(toggleCategories())} />
        <CategoryViewContainerOuter>
          <CategoryViewContainerInner>
            {renderCategories}
          </CategoryViewContainerInner>
        </CategoryViewContainerOuter>
      </>
    );
  else return null;
};

export default React.memo(CategoryListView);
