import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import GenericButton from "../../atoms/GenericButton";
import { trackAnalyticsEvent } from "../../../utils/trackAnalyticsEvent";
import { goUp } from "../../../utils/goUp";
import { FixedContainer, UpIconStyle } from "./styles";

const UpButton: FC<{ setShift: Dispatch<SetStateAction<boolean>> }> = ({
  setShift,
}) => {
  const onUpClick = () => {
    trackAnalyticsEvent("click-up");
    goUp();
  };

  const [showButton, setShowButton] = useState(false);

  const scrollFunction = () => {
    if (
      document.body.scrollTop > 150 ||
      document.documentElement.scrollTop > 150
    ) {
      setShowButton(true);
      setShift(true);
    } else {
      setShowButton(false);
      setShift(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollFunction, false);
    return () => window.removeEventListener("scroll", scrollFunction, false);
    //eslint-disable-next-line
  }, []);

  if (showButton)
    return (
      <FixedContainer>
        <GenericButton square onClick={onUpClick}>
          <UpIconStyle />
        </GenericButton>
      </FixedContainer>
    );
  else return null;
};

export default UpButton;
