import { useDispatch, useSelector } from "react-redux";
import { restartFieldInterface } from "../../interfaces/restaurant-field.interface";
import {
  cartItem,
  getMenu,
  setCachedCart,
} from "../../store/slices/menu.slice";
import { useRouteMatch } from "react-router-dom";
import { useEffect } from "react";
import { validateCache } from "../cashing/validateCache";

export const useFetchMenuOrCache = () => {
  const restaurant = useSelector(
    (state: {
      menuSlice: { response: { restaurant: restartFieldInterface } };
    }) => state.menuSlice.response?.restaurant
  );
  const cart = useSelector(
    (state: { menuSlice: { cart: cartItem[] } }) => state.menuSlice.cart
  );

  const {
    params: { id },
  }: { params: { id: string } } = useRouteMatch();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMenu(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (restaurant && validateCache(id) && !cart.length) {
      dispatch(setCachedCart());
    } else return;
  }, [restaurant, dispatch, cart.length, id]);
  const { name, logo } = restaurant || {};

  return { name, logo };
};
