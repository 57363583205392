import styled, { css, keyframes } from "styled-components";

const lightPulse = keyframes`
  from {
    transform: scale3d(0.95, 0.95, 1);
  }
  to {
    transform: scale3d(1.1, 1.1, 1);
  }
`;

const mainStyles = css`
  background-color: ${({ theme }) => theme.palette.primary.accentBorder};
  color: ${({ theme }) => theme.palette.common.black};
  & svg path {
    fill: ${({ theme }) => theme.palette.common.black} !important;
  }
  width: 55px;
  height: 55px;

  border-radius: 10px;

  box-shadow: ${({ theme }) => theme.shadow.main};
`;

const NavButtonContainer = styled.button<{
  main?: boolean;
  hasNewFeatures?: boolean;
}>`
  background: none;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.palette.common.white};
  width: 70px;
  cursor: pointer;
  white-space: nowrap;
  ${({ main }) => (main ? mainStyles : "")}

  ${({ hasNewFeatures }) =>
    hasNewFeatures
      ? css`
          animation-name: ${lightPulse};
          animation-duration: 1.5s;
          animation-direction: alternate;
          animation-iteration-count: infinite;
          animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
        `
      : ""}
`;

const NavButtonIcon = styled.div<{ isActive?: boolean }>`
  & svg {
    height: 25px;
    width: auto;
  }
  & svg path {
    fill: ${({ isActive, theme }) =>
      isActive
        ? theme.palette.primary.accentBorder
        : theme.palette.common.white};
  }
`;

export { NavButtonContainer, NavButtonIcon };
