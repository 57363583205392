import styled from "styled-components";

const TipsHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`;

const TipsTOC = styled.div`
  text-align: center;
  margin-top: 50px;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.primary.accentBorder};
`;

const TipsLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const TitleText = styled.h3`
  text-align: center;
  margin: 20px;
`;

const TipsLegal = styled.div`
  margin-top: 10px;
  font-size: 12px;
  width: 100%;
  color: ${({ theme }) => theme.palette.primary.accentBorder};
  text-align: center;
`;

export { TipsHolder, TipsTOC, TipsLink, TitleText, TipsLegal };
