import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { useDispatch } from "react-redux";
import MenuLayout from "./components/layouts/MenuLayout";
import MinimalLayout from "./components/layouts/MinimalLayout";
import MenuHome from "./components/screens/MenuHome";
import Checkout from "./components/screens/Checkout";
import ErrorPage from "./components/screens/404";
import { setdeviceId } from "./store/slices/user.slice";
import { useTxidPing } from "./utils/hooks/useTxidPing";
import { useThemeSetup } from "./utils/hooks/useThemeSetup";
import { useLangSetup } from "./utils/hooks/useLangSetup";
import { useSuccessMessage } from "./utils/hooks/useSuccessMessage";

//this should be uncommented if more routes will be added. Improves bundle sizes and tickles Google's balls

// const MenuHome = loadable(() => import("./components/screens/MenuHome"));
// const Checkout = loadable(() => import("./components/screens/Checkout"));

const AppContainerOuter = styled.div`
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  min-height: 100vh;
`;

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setdeviceId());
    //eslint-disable-next-line
  }, []);

  const theme = useThemeSetup();
  useTxidPing();
  useLangSetup();
  const successDialog = useSuccessMessage();

  //in the future - routes should probably be moved to a different file. For now there are only 3 so it's okay.
  //uses the render props pattern to render components in layout wrappers
  return (
    <ThemeProvider theme={theme}>
      <AppContainerOuter>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path={"/menu/:id"}
              render={(props) => (
                <MenuLayout {...props}>
                  <MenuHome />
                </MenuLayout>
              )}
            />
            <Route
              exact
              path={"/order/:id"}
              render={(props) => (
                <MenuLayout {...props}>
                  <MenuHome />
                </MenuLayout>
              )}
            />
            <Route
              exact
              path={"/order/:id/checkout"}
              render={(props) => (
                <MinimalLayout {...props}>
                  <Checkout />
                </MinimalLayout>
              )}
            />
            <Route exact path={"*"} component={ErrorPage} />
          </Switch>
        </BrowserRouter>
        {successDialog}
      </AppContainerOuter>
    </ThemeProvider>
  );
};

export default App;
