import { createSlice } from "@reduxjs/toolkit";

export interface IUiSlice {
  isSearchOpen: boolean;
  isCartOpen: boolean;
  isCategorySelectorOpen: boolean;
  isAboutOpen: boolean;
  isWaiterOpen: boolean;
  treeView: boolean;
}

const initialState: IUiSlice = {
  isSearchOpen: false,
  isCartOpen: false,
  isCategorySelectorOpen: false,
  isAboutOpen: false,
  isWaiterOpen: false,
  treeView: true,
};

export const uiSlice = createSlice({
  name: "ui-slice",
  initialState,
  reducers: {
    toggleSearch: (state) => {
      return {
        ...state,
        isSearchOpen: !state.isSearchOpen,
        isCartOpen: false,
        isCategorySelectorOpen: false,
        isAboutOpen: false,
        isWaiterOpen: false,
      };
    },
    toggleCart: (state) => {
      return {
        ...state,
        isSearchOpen: false,
        isCartOpen: !state.isCartOpen,
        isCategorySelectorOpen: false,
        isAboutOpen: false,
        isWaiterOpen: false,
      };
    },
    toggleCategories: (state) => {
      return {
        ...state,
        isSearchOpen: false,
        isCartOpen: false,
        isCategorySelectorOpen: !state.isCategorySelectorOpen,
        isAboutOpen: false,
        isWaiterOpen: false,
      };
    },
    toggleAbout: (state) => {
      return {
        ...state,
        isSearchOpen: false,
        isCartOpen: false,
        isCategorySelectorOpen: false,
        isAboutOpen: !state.isAboutOpen,
        isWaiterOpen: false,
      };
    },
    toggleWaiter: (state) => {
      return {
        ...state,
        isSearchOpen: false,
        isCartOpen: false,
        isCategorySelectorOpen: false,
        isAboutOpen: false,
        isWaiterOpen: !state.isWaiterOpen,
      };
    },
    resetUiState: () => initialState,
  },
});

export const {
  toggleSearch,
  toggleCart,
  toggleCategories,
  toggleAbout,
  resetUiState,
  toggleWaiter,
} = uiSlice.actions;
export default uiSlice.reducer;
