import styled from "styled-components";
import { ReactComponent as ExpandIcon } from "../../../assets/icons/expandIcon.svg";

const CategoryItemContainer = styled.div<{ nestLevel?: number }>`
  display: flex;
  align-items: center;
  justify-content: ${({ nestLevel }) =>
    nestLevel ? "space-between" : "center"};
  background: ${({ theme }) => theme.palette.primary.elevation1};
  box-shadow: ${({ theme }) => theme.shadow.main};
  border-radius: ${({ theme }) => theme.borders.generalRadius};
  border: ${({ nestLevel }) =>
    (nestLevel ?? 0) <= 1 ? "2px solid transparent" : ""};
  border-top-color: ${({ theme }) => theme.palette.primary.accentBorder};
  padding: 10px;
  margin: ${({ nestLevel }) =>
    !nestLevel || nestLevel === 1
      ? "10px 0 0 10px"
      : `10px 0 0 ${10 * nestLevel}px`};
  @media screen and (min-width: 500px) {
    margin: 10px 0 0 10px;
  }
  cursor: pointer;
  height: ${({ nestLevel }) =>
    !nestLevel || nestLevel === 1 ? "50px" : `${50 - 5 * nestLevel}px`};
`;

const CategoryItemAvatar = styled.img`
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borders.generalRadius};
`;

const CategoryItemLabel = styled.div<{ nestLevel?: number }>`
  word-break: break-word;
  font-weight: bold;
  text-align: center;
  font-size: ${({ nestLevel }) => `calc(100% - ${nestLevel ?? 0}px)`};
`;

const ExpandIconStyled = styled(ExpandIcon)<{ expanded: boolean }>`
  width: 18px;
 transform: ${({ expanded }) => (expanded ? "rotate(180deg)" : "")};
  & path {
    stroke: ${({ theme }) => theme.palette.primary.accentBorder}
`;

export {
  CategoryItemAvatar,
  CategoryItemContainer,
  CategoryItemLabel,
  ExpandIconStyled,
};
