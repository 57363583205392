//just a nice way to handle the analytics logging
//if the new analytics provider is needed - just plop it in here and everything else will be handled automatically

import store from "../store";

type Options = {
  event_category?: string | null;
  event_label?: string | null;
  page_title?: string | null;
};

export const trackAnalyticsEvent = (eventName: string, options?: Options) => {
  const params = new URLSearchParams(window.location.search);
  const isPreview = params.get("preview");
  if (isPreview) return;

  const { id, name } = store.getState().menuSlice.response?.restaurant || {};

  if (!id || !name) return;

  gtag("event", eventName, {
    event_category: `${id}:${name}`,
    page_title: name + " - QRMenu.biz",
    ...options,
  });
};
