import styled from "styled-components";
import { ReactComponent as MenuIcon } from "../../../assets/icons/menuIcon.svg";
import { ReactComponent as TipsIcon } from "../../../assets/icons/tips.svg";

const FixedContainer = styled.div<{ shift: boolean }>`
  position: fixed;
  bottom: 85px;
  right: 20px;
  box-shadow: ${({ theme }) => theme.shadow.main};
  transform: ${({ shift }) => (shift ? "translateX(-60px)" : "")};
  transition: all ease-in-out 0.3s;
`;

const MenuIconStyled = styled(MenuIcon)`
  height: 20px;
  margin-right: 5px;
  width: auto;
  & path {
    fill: ${({ theme }) => theme.palette.primary.highlightIconFill}
`;
const TipsIconStyled = styled(TipsIcon)`
  height: 20px;
  margin-right: 5px;
  width: auto;
  & path {
    fill: ${({ theme }) => theme.palette.primary.highlightIconFill}
`;

export { FixedContainer, MenuIconStyled, TipsIconStyled };
