import { menuCategoryInterface } from "../interfaces/menu-category.interface";

export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

export const getRandomImage = (
  menu?: menuCategoryInterface[]
): string | undefined => {
  const withImages: string[] = [];

  menu?.forEach((category) =>
    category.items.forEach((item) => {
      if (item.imgurl) {
        withImages.push(item.imgurl);
      }
    })
  );

  if (withImages && withImages.length) {
    const randomNumber = getRandomInt(0, withImages.length);
    return withImages[randomNumber];
  } else return;
};
