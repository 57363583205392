import styled, { keyframes } from "styled-components";
import { ReactComponent as UpIcon } from "../../../assets/icons/upIcon.svg";

const SlideIn = keyframes`

  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }`;

const FixedContainer = styled.div`
  animation: ${SlideIn} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;

const UpIconStyle = styled(UpIcon)`
  height: 25px;
  width: auto;
  & path {
    fill: ${({ theme }) => theme.palette.primary.highlightIconFill}
`;

export { UpIconStyle, FixedContainer };
