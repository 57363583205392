import styled, { css } from "styled-components";

const InputWrapper = styled.div`
  width: 100%;
`;

const genericInputStyles = css`
  border-radius: ${({ theme }) => theme.borders.small};
  padding: 10px;
  width: 100%;
  outline: none;
  height: 35px;
  transition: all 0.2s ease-in-out;
`;

const fullInputStyles = css`
  background: ${({ theme }) => theme.palette.primary.elevation1};
  border: 2px solid transparent;
  border-top-color: ${({ theme }) => theme.palette.primary.accentBorder};
  color: ${({ theme }) => theme.palette.common.white};
  &:focus {
    border: 2px solid ${({ theme }) => theme.palette.primary.accentBorder};
  }
`;

const minimalInputStyles = css`
  background: transparent;
  border: 2px solid transparent;
  border-bottom-color: ${({ theme }) => theme.palette.primary.elevation1};
  color: ${({ theme }) => theme.palette.common.white};
  &:focus {
    border-bottom-color: ${({ theme }) => theme.palette.primary.accentBorder};
  }
`;

const InputElement = styled.input<{ variant?: "full" | "minimal" }>`
  ${genericInputStyles};
  ${({ variant }) =>
    variant === "full" ? fullInputStyles : minimalInputStyles}
`;

const InputLabel = styled.label``;

const InputIcon = styled.img``;

export { InputWrapper, InputElement, InputIcon, InputLabel };
