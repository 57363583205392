import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { MenuContainer, NothingFound } from "./styles";
import CopyrightFooter from "../../molecules/CopyrightFooter";
import { useTranslation } from "react-i18next";
import GenericButton from "../../atoms/GenericButton";
import { clearSearchResults } from "../../../store/slices/menu.slice";
import Mark from "mark.js";
import GenericEmptyState from "../../molecules/GenericEmptyState";
import { useUi } from "../../../utils/hooks/useUi";
import { useMenuApiResponse, useMenuSlice } from "../../../utils/hooks/useMenu";
import { menuRenderer } from "../../../utils/menuRenderer";

const MenuHome = () => {
  const { searchResults, searchKey: defaultSearchKey } = useMenuSlice();
  const { menu, restaurant } = useMenuApiResponse();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { treeView, isAnyNotSearchOpen } = useUi();

  useEffect(() => {
    const instance = new Mark("div#searchDiv");
    if (!defaultSearchKey) instance.unmark();
    else
      instance.unmark({
        done: () => setTimeout(() => instance.mark(defaultSearchKey), 50),
      });
  }, [searchResults, defaultSearchKey]);

  const renderMenu = useMemo(() => {
    //if the search is not empty - ditch the default categories from the response and get the ones from the search results
    if (restaurant?.id && !menu.length) {
      if (isAnyNotSearchOpen) return null;
      return (
        <GenericEmptyState
          title={t(
            "SplashScreen:The restaurant has not yet created their menu"
          )}
        />
      );
    }
    if (searchResults && searchResults.length) {
      return menuRenderer(searchResults);
    } else if ((!searchResults || !searchResults.length) && defaultSearchKey) {
      return (
        <NothingFound>
          {t("SearchBar:We could not find any meal matching your search.")}

          <GenericButton
            color="primary"
            onClick={() => dispatch(clearSearchResults())}
            margins={[30, 0, 0, 0]}
          >
            {t("SearchBar:Back to menu")}
          </GenericButton>
        </NothingFound>
      );
    } else if (menu && menu.length) {
      return menuRenderer(menu);
    }
    //eslint-disable-next-line
  }, [menu, searchResults, restaurant, treeView, isAnyNotSearchOpen]);

  return (
    <>
      <MenuContainer>{renderMenu}</MenuContainer>
      <CopyrightFooter />
    </>
  );
};

export default MenuHome;
