import React from "react";
import ReactDOM from "react-dom";
import { AlertInterface } from "../components/molecules/Alert/interface";
import Alert from "../components/molecules/Alert";

export const createRichAlert = (props: AlertInterface) => {
  const el = document.createElement("div");
  el.id = "alertContainer";
  document.body.appendChild(el);

  ReactDOM.render(
    <Alert {...props} />,
    document.getElementById("alertContainer")
  );
};
