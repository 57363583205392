import React from "react";
import {
  BackButton,
  CheckoutContainer,
  CheckoutContainerInner,
} from "./styles";
import GenericButton from "../../atoms/GenericButton";
import { useHistory } from "react-router-dom";
import { ReactComponent as UpIcon } from "../../../assets/icons/upIcon.svg";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { resetUiState } from "../../../store/slices/ui.slice";
import CheckoutForm from "../../organisms/CheckoutForm";
import CartSummary from "../../molecules/CartSummary";

const Checkout = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <CheckoutContainer>
      <GenericButton color="secondary" onClick={() => history.goBack()}>
        <BackButton onClick={() => dispatch(resetUiState())}>
          <UpIcon />
          <h3>{t("Checkout:back")}</h3>
        </BackButton>
      </GenericButton>
      <CheckoutContainerInner>
        <CheckoutForm />
        <CartSummary hideDefaultAction />
      </CheckoutContainerInner>
    </CheckoutContainer>
  );
};

export default Checkout;
