import styled, { css, keyframes } from "styled-components";

const slideOut = keyframes`
  0%{transform: scaleY(0)}
  100%{transform: scaleY(1)}
`;

const WaiterPopupContainerOuter = styled.div`
  position: sticky;
  bottom: 55px;
  width: 100%;
  background: ${({ theme }) => theme.palette.primary.headerFooter};
  border-top: 2px solid ${({ theme }) => theme.palette.primary.accentBorder};
  border-radius: ${({ theme }) => theme.borders.bottomSheets};
  box-shadow: ${({ theme }) => theme.shadow.mainUp};
  z-index: 97;
  user-select: none;
  min-height: 15vh;
  max-height: 60vh;
  transform-origin: bottom center;
  animation: ${slideOut} 0.3s ease-in-out;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    @media screen and (max-width: 768px) {
      width: 0;
    }
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.primary.accent};
    border-radius: ${({ theme }) => theme.borders.generalRadius};
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.primary.elevation1};
    border-radius: ${({ theme }) => theme.borders.generalRadius};
  }
`;

const WaiterPopupContainerInner = styled.div`
  ${({ theme }) => theme.container};
  margin-top: 20px;
  overflow: auto;
  height: 100%;
  padding-bottom: 60px;
`;

const WaiterPopupHeader = styled.h3<{ hasBorderBottom?: boolean }>`
  color: ${({ theme }) => theme.palette.primary.accentBorder};
  width: 100%;
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
  font-weight: normal;
  ${({ hasBorderBottom }) =>
    hasBorderBottom
      ? css`
          border-bottom: 2px solid
            ${({ theme }) => theme.palette.primary.accentBorder};
          padding-bottom: 20px;
        `
      : ""}
`;

const DefaultOptionsHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
`;

const FallbackText = styled.h3`
  text-align: center;
`;

export {
  WaiterPopupContainerOuter,
  WaiterPopupContainerInner,
  WaiterPopupHeader,
  DefaultOptionsHolder,
  FallbackText,
};
