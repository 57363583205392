import React, { FC } from "react";
import { NavButtonInterface } from "./interface";
import { NavButtonContainer, NavButtonIcon } from "./styles";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";

const NavButton: FC<NavButtonInterface> = ({
  Icon,
  onClick,
  isActive,
  children,
  main,
  hasNewFeatures,
}) => {
  const shouldHideText = useWindowSize() < 300;

  return (
    <NavButtonContainer
      onClick={onClick}
      main={main}
      hasNewFeatures={hasNewFeatures}
    >
      <NavButtonIcon isActive={isActive}>
        <Icon />
      </NavButtonIcon>
      {!shouldHideText && children}
    </NavButtonContainer>
  );
};

export default NavButton;
