import styled from "styled-components";
import { createFontFamily } from "../../../utils/hooks/useAdditionalFonts";

const CategoryHeader = styled.h2<{ noPadding?: boolean; nestLevel?: number }>`
  padding-top: ${({ noPadding }) => (noPadding ? 0 : "20px")};
  text-align: inherit;
  & > * {
    font-family: ${({ theme }) => createFontFamily(theme)};
    ${({ theme }) => theme.categoryHeadings}
    font-size: ${({ nestLevel }) =>
      `calc(35px - ${Math.pow(nestLevel ?? 0, nestLevel === 1 ? 0 : 2)}px)`};
  }
`;

const CategoryDescription = styled.h4`
  margin: 5px 0 20px 0;
  text-align: center;
  ${({ theme }) => theme?.categoryDescriptionStyles}
`;

const CategoryContainer = styled.div`
  position: relative;
  ${({ theme }) => theme?.categoryContainerStyles}
`;

const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
`;

const CategoryHeaderContainer = styled.div<{
  shouldJustify: boolean;
  height: number;
}>`
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: ${({ shouldJustify }) =>
    shouldJustify ? "space-between" : "center"};
  text-align: ${({ shouldJustify }) => (shouldJustify ? "left" : "center")};
  ${({ theme, height }) => theme?.categoryHeaderStyles?.(height)}
`;

const CategoryTextContainer = styled.div``;

export {
  CategoryContainer,
  CategoryHeader,
  CategoryDescription,
  ItemsContainer,
  CategoryHeaderContainer,
  CategoryTextContainer,
};
