import styled, { css, keyframes } from "styled-components";

const ripple = keyframes`
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
  }
`;
const pulse = keyframes`
  from {
    transform: scale3d(0.95, 0.95, 1);
  }
  to {
    transform: scale3d(1.1, 1.1, 1);
  }
`;

const rings = (duration: number, delay: number) => css`
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  left: -8px;
  right: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  border: 8px solid rgba(253, 191, 58, 0.5);
  border-radius: 100%;
  animation-name: ${ripple};
  animation-duration: ${duration}s;
  animation-delay: ${delay}s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: -1;
`;

const puffOut = keyframes`
  0% {
    transform: translateZ(1);
    opacity: 1;
  }
  100% {
    transform: translateZ(600px);
    opacity: 0;
  }`;

const SplashContainer = styled.div<{ shouldStart: boolean }>`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: ${Number.MAX_SAFE_INTEGER};
  background: rgba(24, 24, 24, 1);
  animation: ${({ shouldStart }) =>
    shouldStart
      ? css`
          ${puffOut} 2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both
        `
      : undefined};
`;

const SplashLogo = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  img {
    width: 100px;
    height: 100px;
    animation: ${pulse} 1.5s infinite ease alternate;
  }
  &::after {
    ${rings(1.5, 0)}
  }
  &::before {
    ${rings(1.5, 0.5)}
  }
`;

export { SplashContainer, SplashLogo };
