import React from "react";
import {
  CartContainerBg,
  CartContainerInner,
  CartContainerOuter,
  CartItemsContainer,
} from "./style";
import { useDispatch, useSelector } from "react-redux";
import CartItem from "../../molecules/CartItem";
import { useTranslation } from "react-i18next";
import { toggleCart } from "../../../store/slices/ui.slice";
import { useIsInOrderContext } from "../../../utils/hooks/useIsInOrderContext";
import { cartItem, clearSearchResults } from "../../../store/slices/menu.slice";
import CartSummary from "../../molecules/CartSummary";
import { trackAnalyticsEvent } from "../../../utils/trackAnalyticsEvent";
import { useAutoOpenPopup } from "../../../utils/hooks/useAutoOpenPopup";
import { useUi } from "../../../utils/hooks/useUi";

const Cart = () => {
  const { t } = useTranslation();

  const { isCartOpen } = useUi();

  const dispatch = useDispatch();

  const openPopup = () => {
    dispatch(clearSearchResults());
    !isCartOpen && trackAnalyticsEvent("popup-shortlist");
    dispatch(toggleCart());
  };

  useAutoOpenPopup("#shortlist", openPopup);

  const isInOrderContext = useIsInOrderContext();

  const cartItems = useSelector(
    (state: { menuSlice: { cart: cartItem[] } }) => state.menuSlice.cart
  );

  const cartText = isInOrderContext
    ? t(
        "Cart:Nothing in the cart yet. You can add meals or drinks to cart by tapping on their prices"
      )
    : t(
        "Cart:My Picks lets you shortlist meals and make the order without calling the waiter. Just tap the prices in the menu and your picks will appear here."
      );

  if (isCartOpen)
    return (
      <>
        <CartContainerBg onClick={() => dispatch(toggleCart())} />
        <CartContainerOuter>
          <CartContainerInner isOneColumn={true}>
            <CartItemsContainer>
              {cartItems.length
                ? cartItems.map((item) => (
                    <CartItem
                      id={item.id}
                      key={item.priceId}
                      amount={item.amount!}
                      quantity={item.quantity}
                      label={item.label}
                      priceId={item.priceId}
                      itemName={item.itemName}
                    />
                  ))
                : cartText}
            </CartItemsContainer>
            {cartItems.length ? <CartSummary /> : null}
          </CartContainerInner>
        </CartContainerOuter>
      </>
    );
  else return null;
};

export default Cart;
