import BottomNav from "./BottomNav.json";
import About from "./About.json";
import Cart from "./Cart.json";
import CopyrightFooter from "./CopyrightFooter.json";
import SearchBar from "./SearchBar.json";
import SplashScreen from "./SplashScreen.json";
import Checkout from "./Checkout.json";
import ErrorPage from "./ErrorPage.json";
import WaiterPopup from "./WaiterPopup.json";
import Tips from "./Tips.json";
import translation from "./translation.json";

export default {
  BottomNav,
  About,
  Cart,
  CopyrightFooter,
  SearchBar,
  SplashScreen,
  Checkout,
  ErrorPage,
  WaiterPopup,
  Tips,
  ...translation,
};
