import { css } from "styled-components";
import oldPaperBg from "../assets/oldpaperbg.jpg";

export const oldPaperTheme = {
  typography: {
    fontFamily: "Montserrat",
    headingsFontFamily: "Pacifico",
    hasNoWeight: true,
    headingsFontFamilyType: "cursive",
    fontSizes: {
      small: 12,
      mediumSmall: 14,
      medium: 16,
      extraMedium: 19,
      large: 24,
      extraLarge: "3em",
    },
    fontWeights: {
      lighter: 400,
      bold: 600,
      medium: 500,
      extraBold: 700,
    },
  },
  shadow: {
    main: "none",
    mainUp: "none",
    large: "none",
  },
  palette: {
    common: {
      white: "#000",
      black: "#fff",
    },
    primary: {
      main: `url(${oldPaperBg}) repeat top center / contain #E09F70`,
      headerFooter: `url(${oldPaperBg}) repeat top center / cover #E09F70`,
      highlightIconFill: "#fff",
      accent: "#000",
      accentBorder: "#000",
      elevation: "transparent",
      elevation1: "rgba(0, 0, 0, 0.3)",
    },
    custom: {
      successGreen: "#4cd964",
      dangerRed: "#d0021b",
      warningYellow: "#ffcc00",
    },
  },
  borders: {
    generalRadius: "10px 0 0 10px",
    small: "10px 0 0 10px",
    medium: "10px ",
    extraMedium: "10px 0 0 10px",
    topSheets: "0 0 10px 10px",
    bottomSheets: "10px 10px 0 0",
  },
  container: css`
    width: 90%;
    max-width: 1300px;
    margin: auto;
  `,
  cardLayout: (hasImage: boolean) => css`
    display: grid;
    grid-template-columns: ${hasImage ? "1fr 1.5fr" : "1fr"};
    gap: 10px;
    direction: rtl;
    & * {
      direction: ltr;
    }
    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      gap: 0;
    }
    & h3,
    & div,
    & h3 p {
      margin-top: 0;
    }
  `,
  cardProps: {
    padding: "0",
    contentPadding: "5px",
    fontColor: "#000",
    border: (isActive: boolean) => css`
      border: ${({ theme }) =>
        isActive
          ? `2px solid ${theme.palette.primary.accentBorder}`
          : "2px solid transparent"};
      border-top-color: ${({ theme }) => theme.palette.primary.accentBorder};
      border-right: none;
      border-radius: 10px 0 0 10px;
    `,
    inactiveStyles: css`
      filter: grayscale(1);
      opacity: 0.5;
    `,
    cardContentInnerStyles: (hasImage: boolean) => css``,
    priceItemStyles: (isActive?: boolean) => css`
      transition: all 0.3s ease-in-out;
      background: ${isActive ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0)"};
      border: 2px solid #000;
    `,
  },
  cornerLabelStyles: css`
    padding: 7px 10px;
    &.topLeft {
      top: 0;
      left: 0;
      //TODO: these have to be changed, not used anywhere now
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
    }
    &.topRight {
      top: -2px;
      right: 0;
      border-radius: 10px 0 0 10px;
    }
    &.bottomRight {
      bottom: 0;
      right: 0;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &.bottomLeft {
      bottom: 0;
      left: 0;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  `,
  cartItemProps: {
    borderRadius: "10px 0 0 10px",
  },
  desktopColumnsWidth: 400,
  generalContainerOverride: css`
    @media screen and (max-width: 600px) {
      width: 100%;
      padding-left: 60px;
    }
  `,
  prependDescriptionsToCards: true,
  categoryHeadings: css`
    font-size: 34px;
    @media screen and (max-width: 600px) {
      font-size: 24px;
    }
  `,
  categoryContainerStyles: css`
    padding-top: 20px;
  `,
  categoryDescriptionStyles: css`
    text-align: left;
    margin-left: 40px;
    font-size: 22px;
    padding-right: 10px;
    @media screen and (max-width: 600px) {
      margin-left: 0;
    }
  `,
  categoryHeaderStyles: (decorationLineHeight: number) => css`
    transform: rotate(-90deg) translateX(calc(-100% - 20px));
    transform-origin: top left;
    display: block;
    width: auto;
    position: absolute;
    top: 0;
    left: -60px;
    @media screen and (max-width: 600px) {
      left: -80px;
    }
    margin: 0;
    &:after {
      content: "''";
      bottom: -10px;
      right: 0;
      position: absolute;
      width: ${decorationLineHeight - 20}px;
      height: 4px;
      border-radius: 10px;
      background: black;
    }
  `,
};
