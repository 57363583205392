import styled, { keyframes } from "styled-components";

const slideOut = keyframes`
  0%{transform: scaleY(0)}
  100%{transform: scaleY(1)}
`;

const CategoryViewContainerOuter = styled.div`
  position: sticky;
  bottom: 55px;
  width: 100%;
  background: ${({ theme }) => theme.palette.primary.headerFooter};
  border-top: 2px solid ${({ theme }) => theme.palette.primary.accentBorder};
  border-radius: ${({ theme }) => theme.borders.bottomSheets};
  box-shadow: ${({ theme }) => theme.shadow.mainUp};
  z-index: 97;
  user-select: none;
  min-height: 15vh;
  max-height: 60vh;
  transform-origin: bottom center;
  animation: ${slideOut} 0.3s ease-in-out;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    @media screen and (max-width: 768px) {
      width: 0;
    }
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.primary.accent};
    border-radius: ${({ theme }) => theme.borders.generalRadius};
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.primary.elevation1};
    border-radius: ${({ theme }) => theme.borders.generalRadius};
  }
`;

const CategoryViewContainerInner = styled.div`
  ${({ theme }) => theme.container};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-top: 20px;
  overflow: auto;
  height: 100%;
  padding-bottom: 60px;
`;

export { CategoryViewContainerInner, CategoryViewContainerOuter };
