import useQueryParams from "./useQueryParams";
import { useHistory } from "react-router-dom";
import { trackAnalyticsEvent } from "../trackAnalyticsEvent";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { menuTableServiceInterface } from "../../interfaces/menu-table-service.interface";

export const useParamsAndTrackEvent = () => {
  const params = useQueryParams();
  const history = useHistory();
  const token = params.get("token");

  const restaurantId = useSelector(
    (state: { menuSlice: { response: { restaurant: { id: number } } } }) =>
      state.menuSlice.response?.restaurant
  );

  const isInteractive = useSelector(
    (state: {
      menuSlice: {
        response: { interactive: { tableService: menuTableServiceInterface } };
      };
    }) => state.menuSlice.response?.interactive?.tableService
  );

  useEffect(() => {
    if (!restaurantId) return;
    if (token || isInteractive) {
      trackAnalyticsEvent("scan");
    } else {
      if (history.location.pathname.includes("menu")) {
        trackAnalyticsEvent("view");
      } else if (history.location.pathname.includes("menu")) {
        trackAnalyticsEvent("order");
      }
    }
    //eslint-disable-next-line
  }, [restaurantId]);
};
