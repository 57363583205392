import React, { FC, SyntheticEvent, useState } from "react";
import { CategoryItemInterface } from "./interface";
import {
  CategoryItemContainer,
  CategoryItemLabel,
  ExpandIconStyled,
} from "./styles";
import { useDispatch } from "react-redux";
import { toggleCategories } from "../../../store/slices/ui.slice";
import { trackAnalyticsEvent } from "../../../utils/trackAnalyticsEvent";
import ReactMarkdown from "react-markdown";
import { categoryPointersRenderer } from "../../../utils/categoryPointersRenderer";
import GenericButton from "../../atoms/GenericButton";

const CategoryItem: FC<CategoryItemInterface> = ({
  label,
  pointer,
  menus,
  nestLevel,
  expanded: expandedProp,
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    const category = document.getElementById(pointer);
    if (category) {
      const offset = category.offsetTop;
      window.scrollTo(0, offset);
      dispatch(toggleCategories());
      trackAnalyticsEvent("toc-used", { event_label: label });
    }
  };

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = (e: SyntheticEvent) => {
    e.stopPropagation();
    trackAnalyticsEvent(expanded ? "toc-collapsed" : "toc-expanded", {
      event_label: label,
    });
    setExpanded((e) => !e);
  };

  return (
    <>
      <CategoryItemContainer onClick={handleClick} nestLevel={nestLevel}>
        <CategoryItemLabel nestLevel={nestLevel}>
          <ReactMarkdown>{label}</ReactMarkdown>
        </CategoryItemLabel>
        {menus && menus.length && nestLevel === 1 ? (
          <GenericButton
            square
            onClick={toggleExpanded}
            color="secondary"
            small
          >
            <ExpandIconStyled expanded={expanded} />
          </GenericButton>
        ) : null}
      </CategoryItemContainer>
      {menus && (expanded || expandedProp)
        ? categoryPointersRenderer(menus, expanded)
        : null}
    </>
  );
};

export default React.memo(CategoryItem);
