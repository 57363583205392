import { useTheme } from "styled-components";

export const useFontLink = () => {
  const theme: any = useTheme();

  return `https://fonts.googleapis.com/css2?family=${theme.typography.headingsFontFamily.replace(
    " ",
    "+"
  )}${!theme.typography?.hasNoWeight ? ":wght@900" : ""}&display=swap")`;
};

export const createFontFamily = (theme: any) => {
  return `"${theme.typography.headingsFontFamily}", ${theme.typography.headingsFontFamilyType}`;
};
