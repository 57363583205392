import styled from "styled-components";

const CheckoutFormContainer = styled.div`
  background: ${({ theme }) => theme.palette.primary.elevation};
  padding: 10px;
  border-radius: ${({ theme }) => theme.borders.small};
`;

const CheckoutFormForm = styled.form`
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export { CheckoutFormContainer, CheckoutFormForm };
