import React from "react";
import {
  AboutAddress,
  AboutContactsHolder,
  AboutContainerInner,
  AboutContainerOuter,
  AboutDescription,
  AboutHeader,
  AboutHeaderText,
  AboutName,
  AboutPhone,
  AboutType,
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { restartFieldInterface } from "../../../interfaces/restaurant-field.interface";
import { CartContainerBg } from "../Cart/style";
import { toggleAbout } from "../../../store/slices/ui.slice";
import { useTranslation } from "react-i18next";
import { menuMetaInterface } from "../../../interfaces/menu-meta.interface";
import { clearSearchResults } from "../../../store/slices/menu.slice";
import { trackAnalyticsEvent } from "../../../utils/trackAnalyticsEvent";
import { ReactComponent as ShareIcon } from "../../../assets/icons/shareIcon.svg";
import GenericButton from "../../atoms/GenericButton";
import ReactMarkdown from "react-markdown";
import { useAutoOpenPopup } from "../../../utils/hooks/useAutoOpenPopup";

const About = () => {
  const isAboutOpen = useSelector(
    (state: { uiSlice: { isAboutOpen: boolean } }) => state.uiSlice.isAboutOpen
  );

  const dispatch = useDispatch();

  const openPopup = () => {
    dispatch(clearSearchResults());
    !isAboutOpen && trackAnalyticsEvent("popup-about");
    dispatch(toggleAbout());
  };

  useAutoOpenPopup("#about", openPopup);

  const { name, about, address, city, country, phone, type } = useSelector(
    (state: {
      menuSlice: { response: { restaurant: restartFieldInterface } };
    }) => state.menuSlice.response?.restaurant || {}
  );

  const { shareUrl } = useSelector(
    (state: { menuSlice: { response: { meta: menuMetaInterface } } }) =>
      state.menuSlice.response?.meta || {}
  );
  const onShareClick = () => {
    dispatch(clearSearchResults());

    navigator
      .share({
        title: `${name} - QRmenu.biz`,
        text: `${name}\n${about}`,
        url: shareUrl,
      })
      .then(() => trackAnalyticsEvent("share-used"))
      .catch((e) => console.log(e));
  };

  const shouldShowShare = "share" in Navigator.prototype;

  const { t } = useTranslation();

  if (isAboutOpen && name)
    return (
      <>
        <CartContainerBg onClick={() => dispatch(toggleAbout())} />
        <AboutContainerOuter>
          <AboutContainerInner>
            <AboutHeader>
              <AboutHeaderText>
                {t("About:About Us")}
                <AboutName>{name}</AboutName>
              </AboutHeaderText>
              {shouldShowShare && (
                <GenericButton onClick={onShareClick} square>
                  <ShareIcon />
                </GenericButton>
              )}
            </AboutHeader>

            <AboutType>{type}</AboutType>
            <AboutDescription>
              <ReactMarkdown>{about}</ReactMarkdown>
            </AboutDescription>
            <AboutContactsHolder>
              <AboutAddress>
                {address}, {city}, {country}
              </AboutAddress>
              <AboutPhone href={`tel:${phone}`}>
                {phone ? `${t("About:tel")}: ${phone}` : null}
              </AboutPhone>
            </AboutContactsHolder>
          </AboutContainerInner>
        </AboutContainerOuter>
      </>
    );
  else return null;
};

export default About;
