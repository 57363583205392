import styled from "styled-components";

const FooterHolder = styled.footer`
  text-align: center;
  margin-bottom: 30px;
`;

const FooterHeader = styled.h4`
  a {
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

const FooterText = styled.p``;

export { FooterHeader, FooterHolder, FooterText };
